import React, {useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../context/aldersgate';
import TranslationText from '../../components/TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ReportDatagrid(props) {
    const { participants, eventID, report } = props.eventData;

    //const hasReport = (report !== undefined && report !== null && (report.reportStatus === "Completed" || report.reportStatus === "Finalized"));

    const [rowData, setRowData] = useState([]);
    const [additional, setAdditional] = useState(0);
    const [formDisabled, setFormDisabled] = useState(false);
    const [sumbitDisabled, setSubmitDisabled] = useState(true);
    const [totalParticipants, setTotalParticipants] = useState(0);
    const [submitState, setSubmitState] = useState(0);
    const [sumbitError, setSubmitError] = useState(false);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [hasReport, setHasReport] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);
    
    const history = useHistory();

    const translationContainer = new TranslationContainer();

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));
    const xsMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        overflow:'scroll',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '40%' : '85%',
    };

    useEffect(() => {
        if((report !== undefined && report !== null && (report.reportStatus === "Completed" || report.reportStatus === "Finalized"))) {
            setHasReport(true);
            setFormDisabled(true);

            let completedIDsCount = report?.completedIDs?.length || 0;
            let additionalCount = report.completedParticipants - completedIDsCount;

            if(additionalCount < 0)
                additionalCount = 0;

            if(additionalCount > 0) {
                setAdditional(additionalCount);
            }

            setTotalParticipants(completedIDsCount);
        }
    }, []);

    useEffect(() => {
        let data = ParseParticipantData(participants);
        setRowData(data);
    }, [participants]);

    useEffect(() => {
        if(hasReport) {
            setSubmitDisabled(true);
            return;
        }

        if(totalParticipants <= 0) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }

    }, [totalParticipants]);

    useEffect(() => {
        if(hasReport) {
            setFormDisabled(true);
        }
    }, [hasReport]);

    useEffect(() => {
        CalculateCurrentTotal();
    }, [rowData]);

    useEffect(() => {
        CalculateCurrentTotal();
    }, [additional]);

    function ParseParticipantData(arrayData) {
        let participantArray = [];
        //loop through participants
        for(let i=0; i<arrayData.length; i++) {
            let person = {...arrayData[i]};
            person.status = {id: i.toString(), key: i, completed: person.completed};
            participantArray.push(person);
        }

        return participantArray;
    }

    const handleChange = (event, newValue) => {

        if(hasReport)
            return;

        let participantID = event.target.id;

        let participantArray = [...rowData];
        if(participantArray[participantID] !== undefined && participantArray[participantID] !== null) {

            //mark original state
            if(participantArray[participantID].originalVal === undefined)
                participantArray[participantID].originalVal = participantArray[participantID].status.completed;

            participantArray[participantID].status.completed = newValue;
        }

        setRowData(prevState => {
            return participantArray;
        });
    }

    function CalculateCurrentTotal() {
        let count = 0;
        //loop through participants
        for(let i=0; i<rowData.length; i++) {
            if(rowData[i].status.completed)
                count++;
        }

        let total = parseInt(additional, 10) + parseInt(count, 10);

        if(total !== totalParticipants) {
            setTotalParticipants(total);
        }
    }

    const columns = [
        { field: 'fName', headerName: translationContainer.GetTranslation('tables', 'first-name', 'First Name'), flex: 1,editable: true },
        { field: 'lName', headerName: translationContainer.GetTranslation('tables', 'last-name', 'Last Name'), flex: 1,editable: true },
        { field: 'status', headerName: translationContainer.GetTranslation('tables', 'completed', 'Completed'), flex: 1,editable: false, align:"center", headerAlign:"center",
        renderCell: (params) => {
                return <Checkbox id={params.value.id} checked={params.value.completed} onChange={handleChange} />
        } },
    ];

    function OnSubmitReport() {
        setFormDisabled(true);
        setSubmitError(false);
        setSubmitState(1);

        let completedIDs = [];

        for(let i=0; i<rowData.length; i++) {
            if(rowData[i].status.completed) {
                completedIDs.push(rowData[i].adID);
            }
        }

        let totalCount = totalParticipants;
        if(totalCount < 0)
            totalCount = 0;

        let reportObject = {completedParticipants: totalCount, reportStatus: "Completed", completedIDs};

        aldersgateContext.PostToAPI('event/' + eventID + '/report', reportObject, null, 1).then((success, err) => {
            if(success) {
                setSubmitModalOpen(true);
            }
    
            if(err) {
                console.log("Error: " + err);
                setSubmitError(true);
            }

            setSubmitState(2);
    
        }).catch((error) => {
            console.log("Error: " + error);
            setSubmitState(2);
            setSubmitError(true);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/report/?api-key=foo', {

    }

    function GetCheckedCount(countProps) {
        const {arrayData } = countProps;
        let count = 0;
        //loop through participants
        for(let i=0; i<arrayData.length; i++) {
            if(arrayData[i].status.completed)
                count++;
        }

        //let total = count+additional*1;

        return (
            <TableContainer>
                <Table  aria-label="Totals">
                <TableBody>
                    <TableRow>
                        <TableCell align="right"><TranslationText page='tables' dbKey='marked-complete'>Marked Complete</TranslationText>:</TableCell>
                        <TableCell align="center">{count}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right"><TranslationText page='tables' dbKey='additional-participants'>Additional Participants</TranslationText>:</TableCell>
                        <TableCell align="center">
                            <TextField 
                                id="additionalAdd"
                                defaultValue={additional}
                                disabled={formDisabled}
                                size="small"
                                type="number"
                                onBlur={(newValue) =>{
                                    setAdditional(newValue.target.value);
                                }
                                }
                                sx={{width:70}}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right"><b><TranslationText page='tables' dbKey='total-trained'>Total Trained</TranslationText>:</b></TableCell>
                        <TableCell align="center"><b>{totalParticipants}</b></TableCell>
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const GetSubmitBtnIcon = () => {
        if(submitState === 0) {
            return (<SendIcon />);
        } else if(submitState === 1) {
            return (<CircularProgress size={24} />);
        } else if(submitState === 2) {
            if(sumbitError) {
                return (<ErrorIcon />);
            } else {
                return (<CheckCircleOutlineIcon />);
            }
        }
    }

    const GetSubmitBtnText = () => {
        if(submitState === 0) {
            return (translationContainer.GetTranslation('tables', 'submit-report', 'Submit Report'));
        } else if(submitState === 1) {
            return (translationContainer.GetTranslation('tables', 'submiting', 'Submitting...'));
        } else if(submitState === 2) {
            if(sumbitError) {
                return (translationContainer.GetTranslation('tables', 'report-error-submit', 'Error Submitting Report'));
            } else {
                return (translationContainer.GetTranslation('tables', 'report-submitted', 'Report Submitted'));
            }
        }
    }

    const RefreshPage = () => {
        history.go(0);
    }

    //if we get here, the report is not submitted
    return (
        <Paper elevation={1} sx={{p:2}}>
        <Grid container spacing={3}>

            {hasReport ? (
                <Grid item xs={12} mt={2}>
                    <Box sx={{bgcolor:'#eeeeee'}} p={2}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', gap:'4px', fontSize: 24, fontWeight: 'bold', textAlign:'center'}}>
                            <CheckCircleOutlineIcon color="success" fontSize="large" />
                            <Typography variant="h4" component="div" gutterBottom>
                                <TranslationText page='tables' dbKey='report-submitted'>Report Submitted</TranslationText>
                            </Typography>
                        </Box>
                        <Typography variant="body1" component="div" gutterBottom>
                            <TranslationText page='tables' dbKey='report-submitted-note'>This report has been submitted to the global office.</TranslationText></Typography>
                        <Typography variant="body1" component="div" gutterBottom>
                            <TranslationText page='tables' dbKey='report-submitted-thanks'>Thank you for your commitment to accelerating the spread of the Gospel through training leaders of leaders!</TranslationText>
                        </Typography>
                    </Box>
                </Grid>
            ) : null}

            <Grid item xs={12}>
                <Paper elevation={1}>
                    <DataGrid rows={rowData} columns={columns} 
                        rowsPerPageOptions={[25, 50, 75]}
                        pagination
                        autoHeight
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <GetCheckedCount arrayData={rowData} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button size="large" variant="contained" color="primary" disabled={sumbitDisabled} onClick={OnSubmitReport} startIcon={GetSubmitBtnIcon()}>{GetSubmitBtnText()}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal open={submitModalOpen} onClose={() => {setSubmitModalOpen(false)}}>
                    <Box sx={modalStyle}>
                        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center', width:'100%', height:'100%'}}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', gap:'4px', fontSize: 24, fontWeight: 'bold', textAlign:'center'}}>
                                <CheckCircleOutlineIcon color="success" fontSize="large" />
                                <Typography variant="h4" component="div" gutterBottom>
                                    {sumbitError ? translationContainer.GetTranslation('tables', 'report-error-submit', 'Error Submitting Report') : translationContainer.GetTranslation('tables', 'report-submitted', 'Report Submitted')}
                                </Typography>
                            </Box>
                            <Box sx={{fontSize: 16, textAlign:'center'}}>
                                <Typography variant="body1" component="div" gutterBottom>
                                    {sumbitError ? translationContainer.GetTranslation('tables', 'report-error-msg', 'There was an error submitting your report. Please try again.') : translationContainer.GetTranslation('tables', 'report-success-msg', 'Your report has been submitted successfully. Thank you for your commitment to accelerating the spread of the Gospel through training leaders of leaders!')}
                                </Typography>
                            </Box>
                            <Box  sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center'}} mt={4}>
                                <Button variant="contained" color="primary" onClick={() => {RefreshPage()}}><TranslationText page='tables' dbKey='return-event-details'>Return to Event Details</TranslationText></Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Grid>
        </Grid>
        </Paper>
    )

}
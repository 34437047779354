import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../../../context/aldersgate';
import SelectedEventModalView from './SelectedEventModalView';
import SFTModal from '../SFTModal';
import RDTeamList from './RDTeamList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function RDTeamsView(props) {
    const {filterData} = props;
    const {listData} = props;

    const [teamData, setTeamData] = useState([]);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedTeamFilter, setSelectedTeamFilter] = useState(null);
    const [groupIDs, setGroupIDs] = useState(null);
    const [teamName, setTeamName] = useState(null);


    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height:'80%', 
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
      };

    useEffect(() => {
        if(listData !== undefined && listData !== null)
            setTeamData(listData);
    }, [listData]);

    useEffect(() => {
        if(filterData !== undefined && filterData !== null)
            setSelectedTeamFilter(filterData);
    }, [filterData]);


    const viewTeamDetails = (team) => {
        //find the team from the listData by team (id)
        listData.forEach(element => {
            if(element.id === team) {
                setGroupIDs([element.adID]);
                setTeamName(element.name);
                setActionModalOpen(true);

                return;
            }
        });
    }

    const handelModalClose = () => {
        setActionModalOpen(false);
    }

    return (
        <Box sx={{ height: '300px'}}>
            <RDTeamList listData={teamData} onRowClick={viewTeamDetails} />
            <Modal
                open={actionModalOpen}
                onClose={handelModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <SFTModal filterData={selectedTeamFilter} groupIDs={groupIDs} name={teamName} closeModal={() => { setActionModalOpen(false) }} />
                </Box>
            </Modal>
        </Box>
    );
}
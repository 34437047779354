import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import RDTeamListItem from './RDTeamListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


export default function RDTeamList(props) {
    const {  listData, onRowClick } = props;

    
    const [groupList, setGroupList] = useState([]);
    const [sortBy, setSortBy] = useState('endingDate');
    const [sortDirection, setSortDirection] = useState('desc');

    const [dropDownState, setDropDownState] = useState(0);

    const [currentPage, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [displayRows, setDisplayRows] = useState([]);

    let fontSizeTitle = 14;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {

        if(listData === undefined || listData === null || listData.length <= 0)
            return;

        let sortData = [...listData];

        let i = 0;
        let rows = [];
        sortData.sort(handleSortBy);
        sortData.forEach(person => {
            let processedRow = {...person, elementID:i, onRowClick:onRowClick};
            i++;
            rows.push(processedRow);
        });

        setGroupList(rows);
    }, [listData, sortBy, sortDirection]);

    useEffect(() => {
        let page = currentPage ? currentPage : 0;
        let count = groupList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        let rows = groupList.slice(start, end);
        setDisplayRows(rows);
    }, [groupList, currentPage, rowsPerPage]);

    // useEffect(() => {
    //     if(smMatches) {
    //         setRowsPerPage(15);
    //     } else {
    //         setRowsPerPage(5);
    //     }

    //     setPage(0);
    // }, [smMatches]);
    

    const handleSortBy = (a, b) => {
        if(sortDirection === 'asc') {
            if(a[sortBy] < b[sortBy]) {
                return -1;
            }
            if(a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        } else {
            if(a[sortBy] < b[sortBy]) {
                return 1;
            }
            if(a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        }
    }


    function ChangeSort (event, data) {
        let { fieldID, direction } = data;
        console.log('ChangeSort: ' + fieldID + ' ' + direction);

        setSortBy(fieldID);
        setSortDirection(direction);
    }

    const RenderSortArrow = (arrowProps) => {
        const { fieldID } = arrowProps;
        if(sortBy === fieldID) {
            if(sortDirection === 'asc') {
                let parameter = { fieldID: fieldID, direction: 'desc' }
                return (
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                        <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                            <ArrowDropUpIcon  sx={{color:'#000000'}}/>
                        </IconButton>
                    </Box>
                )
            } else if(sortDirection === 'desc') {
                let parameter = { fieldID: 'updateAt', direction: 'desc' }
                return (
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                        <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                            <ArrowDropDownIcon sx={{color:'#000000'}}/>
                        </IconButton>
                    </Box>
                )
            }
        } else {
            let parameter = { fieldID: fieldID, direction: 'asc' }
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

    }

    const RenderHeader = () => {
        return (
            <Box>
                <Grid container p={1} sx={{backgroundColor:"#ffffff"}}>
                    <Grid item xs={4}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                                        <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                            Team Name
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                        
                    <Grid item xs={4}  sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'end', alignItems:'center'}}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'end', alignItems:'center', gap:'10px'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                Events
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center', alignContent:'center'}}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                        Leaders Trained
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        );
    }

    const handleDropdownChange = (event) => {
        setDropDownState(event.target.value);
        switch(event.target.value) {
            case 0:
                setSortBy('updatedAt');
                setSortDirection('desc');
                break;
            case 1:
                setSortBy('eventName');
                setSortDirection('asc');
                break;
            case 2:
                setSortBy('eventType');
                setSortDirection('asc');
                break;
            default:
                break;
        }
    }

    const RenderFooter = () => {

        let page = currentPage ? currentPage : 0;
        let count = groupList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        const RenderTools = () => {
            if(displayRows.length <= 0) //if no rows, don't show tools
                return (<Box></Box>);

            return (
                    <Box>
                </Box>
            );
        }

        //footer with pagination on right side
        return (
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center'}} >
                 
                 {<RenderTools />}

                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left', color:'bbbbbb' }}>
                        {start+1}-{end} of {count}
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageBackward}>
                            <KeyboardArrowLeftIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageForward}>
                            <KeyboardArrowRightIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const SetPageForward = () => {
        setPage( prev => {
            let newPage = currentPage + 1;

            if(newPage * rowsPerPage > groupList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    const SetPageBackward = () => {
        setPage( prev => {
            let newPage = currentPage - 1;

            if(newPage * rowsPerPage > groupList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }
    

    return (
        <Box mb={1} sx={{display:'flex', flexDirection:'column', justifyContent:'space-between', width:'100%', height:'300px'}}>
            <Box>
                <RenderHeader />
            </Box>
            <Box sx={{overflow: 'auto', display:'flex', flexDirection:'column', height:'100%'}}>
                <Box> 
                    {(displayRows.length > 0) ?  displayRows.map((rowItemData) => (
                        <RDTeamListItem key={rowItemData.id} rowData={rowItemData} />
                    )) : (<Box><Box pt={3}>No Teams</Box></Box>)}
                </Box>
            </Box>
            <RenderFooter />
        </Box>
    )
}
import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import CircularProgress from '@mui/material/CircularProgress';

export default function RDEventMethod(props) {

    const {filterData} = props;
    const { groupIDs } = props;

    const [titleData, setTitleData] = useState(['2022', '2023', '2024', '2025']);
    const [countData, setCountData] = useState([{ data: [0, 0, 0, 0], label:'In-Person' }, { data: [0, 0, 0, 0], label:'Online' }, { data: [0, 0, 0, 0], label:'Hybrid' }]);
    const [receivedData, setReceivedData] = useState(false);

    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetEventsCount();
    }, [groupIDs, filterData]);

    const GetEventsCount = () => {
        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(filterData?.startDate !== undefined && filterData?.startDate !== null)
            query += '&startingDate=' + filterData?.startDate.toISOString();

        if(filterData?.endDate !== undefined && filterData?.endDate !== null)
            query += '&endingDate=' + filterData?.endDate.toISOString();
    
        query += '&years=4';
        
        aldersgateContext.GetFromAPI('stats/r/count/histeventmethods', query, 1).then((success, err) => {
            if(success) {
                //console.log("Success: " + JSON.stringify(success));
                ProcessData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ProcessData = (data) => {
        if(data === undefined || data === null)
            return;

        let tempTitleData = [];
        let methodData = new Map();
        data.forEach(element => {
            
            tempTitleData.push(element.year);

            element.data.forEach(method => {
                if(methodData.has(method.type)) {
                    let temp = methodData.get(method.type);
                    temp.push(method.count);
                    methodData.set(method.type, temp);
                } else {
                    methodData.set(method.type, [method.count]);
                }
            });
            
        });

        let tempCountData = [];
        methodData.forEach((value, key) => {
            tempCountData.push({data: value, label:key});
        });

        setTitleData(tempTitleData);
        setCountData(tempCountData);
        setReceivedData(true);
    }


    return(
        <Paper>
            <Box p={1}>
                <Box sx={{height:'50px'}}>
                    <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                        Events by Method
                    </Typography>
                </Box>
                <Box sx={{height:'300px'}}>
                    {receivedData ? <BarChart
                        xAxis={[
                            {
                            id: 'barCategories',
                            data: titleData,
                            scaleType: 'band',
                            },
                        ]}
                        series={
                            countData
                        }
                        height={300}
                    />
                    
                    : <Box sx={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>}
                    
                </Box>
            </Box>
        </Paper>
    );
}
import React, { useContext } from 'react';
import SophConstants from "../constants/SophConstants";

export const AldersgateContext = React.createContext();

export class AldersgateConnection {
    static instance = null;
    static userSession = null;
    static logoutUser = null;
    static apiKey = 'foo';
    static expiryInterval = null;
    static heartbeatInterval = null;

    static getInstance() {
        if(AldersgateConnection.instance === null) {
            AldersgateConnection.instance = new AldersgateConnection();
        }
    }

    //cosntructor
    constructor() {
        if(AldersgateConnection.instance !== null) {
            return AldersgateConnection.instance;
        }  
    }

    ClearAccountIntervals() {
        //stop heartbeat intervals
        if(AldersgateConnection.heartbeatInterval !== undefined && AldersgateConnection.heartbeatInterval !== null) {
            clearInterval(AldersgateConnection.heartbeatInterval);
            AldersgateConnection.heartbeatInterval = null;
        }

        if(AldersgateConnection.expiryInterval !== undefined && AldersgateConnection.expiryInterval !== null) {
            clearInterval(AldersgateConnection.expiryInterval);
            AldersgateConnection.expiryInterval = null;
        }
    }

    SetLogoutUser(func) {
        if(AldersgateConnection.logoutUser == undefined || AldersgateConnection.logoutUser == null) {
            AldersgateConnection.logoutUser = () => {
                if(AldersgateConnection.heartbeatInterval !== undefined && AldersgateConnection.heartbeatInterval !== null) {
                    clearInterval(AldersgateConnection.heartbeatInterval);
                    AldersgateConnection.heartbeatInterval = null;
                }
        
                if(AldersgateConnection.expiryInterval !== undefined && AldersgateConnection.expiryInterval !== null) {
                    clearInterval(AldersgateConnection.expiryInterval);
                    AldersgateConnection.expiryInterval = null;
                }

                func();
            }
        }

        return;
    }

    SetUserSession(sessionData) {
        //console.log("Setting user sessionData: " + JSON.stringify(sessionData));
        AldersgateConnection.userSession = sessionData; //loggedIn:false, token:null, level:0, adID:null, expiry:null

        if(sessionData !== undefined && sessionData !== null && sessionData?.loggedIn === true) {

            //if interval is null, create a new one
            if(AldersgateConnection.heartbeatInterval === undefined || AldersgateConnection.heartbeatInterval === null) {

                AldersgateConnection.heartbeatInterval = setInterval(() => {
                    //if logged in
                    if(AldersgateConnection.userSession !== undefined && AldersgateConnection.userSession !== null && AldersgateConnection.userSession.loggedIn === true) {
                        this.GetFromAPI('user/hb', null, -1).then((success, err) => {
                            if(success) {
                                console.log("Heartbeat success");
                            }
                
                            if(err) {
                                console.log("Heartbeat error: " + err);
                            }
                
                        }).catch((error) => {
                            console.log("Heartbeat error: " + error);
                        });
                    }
                }, 300000);
            }

            //if interval is null, create a new one
            if(AldersgateConnection.expiryInterval === undefined || AldersgateConnection.expiryInterval === null) {

                AldersgateConnection.expiryInterval = setInterval(() => {
                    console.log("Checking token expiry");
                    if(AldersgateConnection.userSession !== undefined && AldersgateConnection.userSession !== null && AldersgateConnection.userSession.loggedIn === true) {
                        //check if token is expired
                        var now = new Date();
                        var expiry = new Date(AldersgateConnection.userSession?.expiry);
                        if(now > expiry) {
                            //clear token
                            if (AldersgateConnection.logoutUser !== undefined && AldersgateConnection.logoutUser !== null) {
                                AldersgateConnection.logoutUser();
                            }
                        }
                    }
                }, 61000);
            }
        }
    
    }

    // Function to make a GET request to the API server
    GetFromAPI(url, urlVars, authLevel) {
        return new Promise((resolve, reject) => {

            let authString = "api-key=" + AldersgateConnection.apiKey;
            if(authLevel !== undefined && authLevel !== null) {
                if(AldersgateConnection.userSession !== undefined && AldersgateConnection.userSession !== null) {
                    //check if token is expired
                    var now = new Date();
                    var expiry = new Date(AldersgateConnection.userSession.expiry);
                    if(now > expiry) {
                        //clear token
                        if (AldersgateConnection.logoutUser !== undefined && AldersgateConnection.logoutUser !== null) {
                            console.log("Token expired - logging out");
                            AldersgateConnection.logoutUser();
                        } else {
                            console.log("Token expired - no logout function");
                        }

                        reject('Unauthorized');

                        return;
                    }
                }

                if(authLevel > AldersgateConnection.userSession.authLevel) {
                    reject('Forbidden');
                    return;
                }

                authString += "&token=" + AldersgateConnection.userSession.token;
            }

            //construct url
            let fetchUrl = SophConstants.API_ADDR + "/api/" + url;
            if(urlVars !== undefined && urlVars !== null &&  urlVars !== "") {
                fetchUrl += "/?"+ urlVars;
                fetchUrl += "&" + authString;
            } else {
                fetchUrl += "?" + authString;
            }

            console.log("Fetching: " + fetchUrl);

            fetch(fetchUrl, {
                method: 'get',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                body: null
            }).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    response.json().then((jsonData) => {
                        resolve(jsonData);
                    }).catch((err) => {
                        reject(err);
                    });
                } else if (response.status === 401) {
                    // Clear tokens and log user out
                    if (AldersgateConnection.logoutUser !== undefined && AldersgateConnection.logoutUser !== null) {
                        AldersgateConnection.logoutUser();
                    } else {
                        console.log("No logout function");
                    }
                    reject('Unauthorized');
                } else if (response.status === 403) {
                    reject('Forbidden');
                } else if (response.status === 404) {
                    reject('Not Found');
                } else if (response.status === 418) {
                    reject("I'm a teapot");
                } else if (response.status === 501) {
                    reject('Not Implemented');
                } else {
                    reject('Unknown Error');
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    // Function to make a POST request to the API server
    PostToAPI(url, data, urlVars, authLevel, contentType) {
        return new Promise((resolve, reject) => {
            let postContentType = contentType || 'application/json';
            let useJson = (postContentType === 'application/json');
            let useHeader = true;
            if(postContentType == 'multipart/form-data') {
                useHeader = false;
            }

            let authString = "api-key=" + AldersgateConnection.apiKey;
            if(authLevel !== undefined && authLevel !== null) {
                if(AldersgateConnection.userSession !== undefined && AldersgateConnection.userSession !== null) {
                    //check if token is expired
                    var now = new Date();
                    var expiry = new Date(AldersgateConnection.userSession.expiry);
                    if(now > expiry) {
                        //clear token
                        if (AldersgateConnection.logoutUser !== undefined && AldersgateConnection.logoutUser !== null) {
                            AldersgateConnection.logoutUser();
                        }

                        reject('Unauthorized');

                        return;
                    }
                }

                if(authLevel > AldersgateConnection.userSession.authLevel) {
                    reject('Forbidden');
                    return;
                }

                authString += "&token=" + AldersgateConnection.userSession.token;
            }

            //construct url
            let fetchUrl = SophConstants.API_ADDR + "/api/" + url;
            if(urlVars !== undefined && urlVars !== null &&  urlVars !== "") {
                fetchUrl += "/?"+ urlVars;
                fetchUrl += "&" + authString;
            } else {
                fetchUrl += "?" + authString;
            }

            console.log("Posting: " + fetchUrl);

            fetch(fetchUrl, {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: (useHeader) ? {'Content-Type': postContentType} : undefined,
                body: (useJson) ?  JSON.stringify(data) : data
            })
                .then(response => {
                if (response.status === 200 || response.status === 201) {
                    response.json().then((jsonData) => {
                        resolve(jsonData);
                    }).catch((err) => {
                        reject(err);
                    });
                } else if (response.status === 401) {
                    // Clear tokens and log user out
                    if (AldersgateConnection.logoutUser !== undefined && AldersgateConnection.logoutUser !== null) {
                        AldersgateConnection.logoutUser();
                    }
                    reject('Unauthorized');
                } else if (response.status === 403) {
                    reject('Forbidden');
                } else if (response.status === 404) {
                    reject('Not Found');
                } else if (response.status === 418) {
                    reject("I'm a teapot");
                } else if (response.status === 501) {
                    reject('Not Implemented');
                } else {
                    reject('Unknown Error - ' + response.status);
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }
}

// Step 2: Create a provider component
export function AldersgateProvider({ children }) {
  const aldersgateInstance =  AldersgateConnection.getInstance();

  return (
    <AldersgateContext.Provider value={aldersgateInstance}>
      {children}
    </AldersgateContext.Provider>
  );
}

// A hook to make it easier to use the context
export function UseAldersgateConnection() {
  return useContext(AldersgateContext);
}



/*
    
import { AldersgateContext } from '../context/aldersgate';

    const aldersgateContext = useContext(AldersgateContext);

    aldersgateContext.GetFromAPI('event/' + props.match.params.id).then((success, err) => {

        if(success) {
            setEventData(success);
        }

        if(err) {
            console.log("Error: " + err);
        }

    }).catch((error) => {
        console.log("Error: " + error);
    });


*/

import React, {useEffect, useState } from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import TranslationText from '../TranslationText';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';



export default function ResourcesFilter(props) {
    const translationContainer = new TranslationContainer();
    const { category, disableCategory, onSearch } = props;

    const [filterParams, setFilterParams] = useState({category: 'Module 1', type: 'all', language: translationContainer.GetLanguage(), title: ''});

    useEffect(() => {
        setFilterParams({...filterParams, category: category || 'Module 1'});
    }, [category]);

    useEffect(() => {
        onSearch(filterParams);
    }, [filterParams]);

    const handleCategoryChange = (event) => {

        const { value } = event.target;

        setFilterParams({...filterParams, category: value});
    }

    const handleTypeChange = (event) => {
        const { value } = event.target;

        setFilterParams({...filterParams, type: value});
    }

    const handleLanguageChange = (event) => {
        const { value } = event.target;

        setFilterParams({...filterParams, language: value});
    }

    const handleValueChange = (event) => {
        const { id, value } = event.target;

        setFilterParams({...filterParams, [id]: value});
    }

    const handleSearch = () => {
        onSearch(filterParams);
    }

    if(disableCategory) {
        return null;
    }

    return (
        <Box sx={{paddingLeft:'5px', paddingRight:'5px'}}>
        <Box sx={{display:'flex', direction:'row', width:'100%', gap:'10px'}}>
            <FormControl fullWidth>
                <InputLabel id="catLable"><TranslationText page='tables' dbKey='event-type'>Training</TranslationText></InputLabel>
                <Select
                labelId="catLable"
                id="category"
                value={filterParams.category}
                label={translationContainer.GetTranslation('tables', 'event-type', 'Training')}
                onChange={handleCategoryChange}
                size='small'
                >
                <MenuItem value="Module 1">{translationContainer.GetTranslation('tables', 'hm1', 'Module 1')}</MenuItem>
                <MenuItem value="Module 2">{translationContainer.GetTranslation('tables', 'hm2', 'Module 2')}</MenuItem>
                <MenuItem value="Journey">{translationContainer.GetTranslation('tables', 'hmj', 'Journey')}</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth sx={{width:'350px'}}>
                <InputLabel id="typeLable"><TranslationText page='tables' dbKey='type'>Type</TranslationText></InputLabel>
                <Select
                labelId="typeLable"
                id="type"
                value={filterParams.type}
                label={translationContainer.GetTranslation('tables', 'type', 'Type')}
                onChange={handleTypeChange}
                size='small'
                >
                <MenuItem value="all">{translationContainer.GetTranslation('tables', 'all', 'All')}</MenuItem>
                <MenuItem value="video">{translationContainer.GetTranslation('tables', 'video', 'Video')}</MenuItem>
                <MenuItem value="pdf">PDF</MenuItem>
                <MenuItem value="doc">DOC</MenuItem>
                <MenuItem value="ppt">PPT</MenuItem>
                </Select>
            </FormControl>

            <FormControl id="language" fullWidth>
                <InputLabel id="langLable"><TranslationText page='tables' dbKey='language'>Language</TranslationText></InputLabel>
                <Select
                labelId="langLable"
                id="language"
                value={filterParams.language}
                label="Language"
                onChange={handleLanguageChange}
                size='small'
                >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="fr">Français</MenuItem>
                <MenuItem value="pt">Português</MenuItem>
                <MenuItem value="es">Español</MenuItem>
                <MenuItem value="ru">русский</MenuItem>
                </Select>
            </FormControl>
    
        </Box>
        </Box>
    )
}
import React, {useEffect, useState, useContext } from "react";
import { makeStyles } from '@mui/styles';
import { AldersgateContext } from "../context/aldersgate";
import TranslationText from "../components/TranslationText";
import UserLogin from "../components/user/UserLogin";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';



const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        }
    });


    export default function EmailConfirm(props) {
        const { confirmCode } = props?.match?.params;

        const [confirmCodeState, setConfirmCodeState] = useState(0);
        const [emailConfirmCode, setEmailConfirmCode] = useState(confirmCode);
        const aldersgateContext = useContext(AldersgateContext);

        useEffect(() => {
            setEmailConfirmCode(confirmCode);
            ConfirmValidCode();

        }, [confirmCode]);


        const ConfirmValidCode = () => {
            if(confirmCode === undefined || confirmCode === null || confirmCode === "") {
                setConfirmCodeState(1);
                return;
            }

            //reach out to api to confirm code
            let confirmCodeData = {code: emailConfirmCode};

            aldersgateContext.PostToAPI('user/confirm', confirmCodeData).then((success, err) => {
                console.log(JSON.stringify(success, null, 2));
                if(success) {
                    
                    if(success && success.success == true) {
                        setConfirmCodeState(2);
                    } else {
                        setConfirmCodeState(2);
                    }

                    return;
                }
        

                console.log("Error: " + err);
                setConfirmCodeState(1);
                
        
            }).catch((error) => {
                console.log("Error: " + error);
                setConfirmCodeState(1);
            });

            //fetch(SophConstants.API_ADDR + '/api/user/confirm' + '?api-key=foo', {

            
        }

        const RenderCodeState = () => {
            if(confirmCodeState === 0) {
                return (<Box><Box><Typography variant="h5" gutterBottom component="div"><TranslationText page='tables' dbKey='acc-confirming-email'>Confirming Email...</TranslationText></Typography></Box><Box m={4}><CircularProgress /></Box></Box>);
            }
            
            if(confirmCodeState === 1) {
                return (<Box><Box><Typography variant="h5" gutterBottom component="div"><TranslationText page='tables' dbKey='acc-email-c-err'>Error confirming email or account already confirmed</TranslationText></Typography></Box><Box m={4}><Typography variant="body1" gutterBottom component="div"><TranslationText page='tables' dbKey='acc-err-contact'>Please contact the administrator if you believe this is an error.</TranslationText></Typography></Box></Box>);
            }
            
            if(confirmCodeState === 2) {
                return (<Box><Box><Typography variant="h5" gutterBottom component="div"><TranslationText page='tables' dbKey='acc-email-confirmed'>Email Confirmed</TranslationText></Typography></Box><Box m={4}><Typography variant="body1" gutterBottom component="div"><TranslationText page='tables' dbKey='acc-login-now'>You may now login to your account.</TranslationText></Typography></Box><Box><UserLogin redirectPage={"/dashboard"}/></Box></Box>);
            }
        }

        return (
            <Box>
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box mt={4} bgcolor="background.paper" >
                                    <Typography variant="h4" gutterBottom component="div">
                                        <TranslationText page='tables' dbKey='acc-confirm-title'>Account Confirmation</TranslationText>
                                    </Typography>

                                </Box>

                                <RenderCodeState />
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                </Grid>
            </Box>

      )
  }
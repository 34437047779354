import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CardActionArea, CardActions } from '@mui/material';

import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';
import CircularProgress from '@mui/material/CircularProgress';

function GetMediumIcon(medium) {
    switch (medium) {
      case "Online":
        return <TapAndPlayIcon />;
        case "In-Person":
          return <GroupIcon />;
          case "Hybrid":
            return <StreetviewIcon />;
      default:
        break;
    }
  }

export default function ADPeopleListCard(props) {
    const { rowItemData } = props;
    const [value, setValue] = useState(0);
    const [buttonEnabled, setButtonEnabled ] = useState(true);
    const [submitStatus, setSubmitting] = useState({status:0});

    const history = useHistory();

    useEffect(() => {
        setButtonEnabled(rowItemData.createSFButtonEnabled);
    }, [rowItemData]);

    useEffect(() => {

        if(rowItemData.salesforceID !== undefined && rowItemData.salesforceID !== null) {
            setSubmitting({status:2});
        } else {
            if(submitStatus.status !== 1)
                setSubmitting({status:0});
        }
        
    }, [rowItemData]);

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    const GetAccountLevel = (level) => {
        switch (level) {
            case -1:
                return 'Banned';
            case 0:
                return 'Member';
            case 1:
                return 'Leader';
            case 2:
                return 'National Coordinator';
            case 3:
                return 'GET';
            case 4:
                return 'Super Admin';
            default:
                return 'Unknown';
        }
    }

    return (
        <Card square={true} sx={{ border: 0, boxShadow: 0, backgroundColor:bgColor, ':hover': {backgroundColor:'#E0E0E0'}}}>
            <CardActionArea onClick={() => rowItemData.onRowClick(rowItemData.id)}> 
                <CardContent>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start', gap:'10px'}}>
                        <Box pt={1}>
                            
                        </Box>
                        <Box sx={{width:'100%'}}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                        <Box>
                                            <Typography color="text.primary" sx={{ fontSize: 16, textAlign:'left'}}>
                                                {rowItemData.fName} {rowItemData.lName}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                                {rowItemData.location}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                            {rowItemData.eventType} - {rowItemData.eventMedium}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                                {rowItemData.location}
                                            </Typography>
                                        </Box>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-end'}}>
                                    <Box>{rowItemData?.user?.fName} {rowItemData?.user?.lName}</Box>
                                    <Box>
                                        {GetAccountLevel(rowItemData.authLevel)}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../../../context/aldersgate';
import SelectedEventModalView from './SelectedEventModalView';
import RDCountriesList from './RDCountriesList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function RDCountriesView(props) {
    const {listData} = props;

    const [teamData, setTeamData] = useState([]);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);


    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (!smMatches) ? '80%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    useEffect(() => {
        if(listData !== undefined && listData !== null)
            setTeamData(listData);
    }, [listData]);



    const viewEventDetails = (event) => {
        
        

    }

    const handelModalClose = () => {
        setActionModalOpen(false);
    }

    return (
        <Box sx={{ height: '300px'}}>
            <RDCountriesList listData={teamData} onRowClick={viewEventDetails} />
            <Modal
                open={actionModalOpen}
                onClose={handelModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <SelectedEventModalView eventData={selectedEvent} closeModal={() => { setActionModalOpen(false) }} />
                </Box>
            </Modal>
        </Box>
    );
}
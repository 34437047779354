import React, {useState, useEffect, useContext} from "react";
import { NavBreadcrumbs } from "./../components";
import { AldersgateContext } from "../context/aldersgate";
import TranslationContainer from "../localization/TranslationContainer";
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import UserLogin from "../components/user/UserLogin";
import DashboardView from "./../components/dashboard/DashboardView";
import RegionView from "./../components/dashboard/Region/RegionView";
import EventsView from "../components/dashboard/EventsView";
import PeopleView from "../components/dashboard/PeopleView";
import ReportsView from "../components/dashboard/Reports/ReportsView";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GroupsIcon from '@mui/icons-material/Groups';

function DisplayPage(pageProps) {
    let history = useHistory();
    const { pageNumber, lang } = pageProps;

    if(lang === undefined || lang === null) {
        return null;
    }

    switch(pageNumber) {
        case 0:
            window.history.replaceState(null, "ILI Training", "/"+ lang + "/dashboard");
            return <DashboardView />;
        case 1:
            window.history.replaceState(null, "ILI Training", "/"+ lang + "/dashboard/events");
            return <EventsView />;
        case 2:
            window.history.replaceState(null, "ILI Training", "/"+ lang + "/dashboard/people");
            return <PeopleView />;
        case 3:
            window.history.replaceState(null, "ILI Training", "/"+ lang + "/dashboard/region");
            return <RegionView />; //<GroupsView />;
        case 4:
            window.history.replaceState(null, "ILI Training", "/"+ lang + "/dashboard/reports");
            return <ReportsView />;

        default:
            window.history.replaceState(null, "ILI Training", "/"+ lang + "/dashboard");
            return <DashboardView />;
    }
}

export default function Dashboard (props) {

    const [navPage, setNavPage] = useState(0);
    const authContext = useContext(AuthContext);
    const [aState, setAState] = useState(authContext.accountState);
    const [language, setLanguage] = useState(null);
    const [showRegion, setShowRegion] = useState(false);

    const translationContainer = new TranslationContainer();

    const aldersgateContext = useContext(AldersgateContext);
    

    useEffect(() => {
        if(props.match != undefined && props.match.params != undefined && props.match.params != null) {
            let page = 0;
            if(props.match.params.page != undefined) {
                
                switch(props.match.params.page) {
                    case "events":
                        page = 1;
                        break;
                    case "people":
                        page = 2;
                        break;
                    case "region":
                        page = 3;
                        break;
                    case "reports":
                        page = 4;
                        break;
                    default:
                        page = 0;
                        break;
                }
            }
            
            
            let lang = props.match.params.lang;
            if(lang === undefined || lang === null) {
                lang = "en";
            }

            setLanguage(lang);

            setNavPage(page);
        }

        return () => {
            //cleanup
        }

    }, []);
    
    React.useEffect(() => {
        setAState({...authContext.accountState});
        GetMemberGroupsList();

        //on cancel
        return () => {
            //cleanup
        }

    }, [authContext.accountState]);

    const GetMemberGroupsList = () => {
        if(aState.loggedIn === false) {
            return;
        }
        
        aldersgateContext.GetFromAPI('group/getlist/viewgroups', '', 1).then((success, err) => {
            if(success) {
                let showRegion = false;
                if(success.length > 0) {
                    success.forEach(element => {
                        if(element?.groupmember?.groupRole > 3) {
                            showRegion = true;
                        }
                    });
                }

                if(showRegion) {
                    setShowRegion(true);
                }
                    
            }
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    //check for token, block if not logged in
    if(aState.loggedIn === false) {
      return <UserLogin />
    }

    return (
        <Box m={1.5} bgcolor="background.paper" sx={{flexGrow:1}} mb={10}>
            <Grid container >
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    
                    <NavBreadcrumbs crumbs={[]} currentPage={translationContainer.GetTranslation('tables', 'dashboard', 'Dashboard')} />

                    <DisplayPage pageNumber={navPage} lang={language}/>

                </Grid>
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
            </Grid>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                showLabels
                value={navPage}
                onChange={(event, newValue) => {
                    setNavPage(newValue);
                }}
                >
                <BottomNavigationAction label={translationContainer.GetTranslation('tables', 'home', 'Home')} icon={<HomeIcon />} />
                {authContext?.accountState?.authLevel > 0 ? 
                    <BottomNavigationAction label={translationContainer.GetTranslation('tables', 'events-title', 'Events')} icon={<ListAltIcon />} />
                : null }
                {authContext?.accountState?.authLevel > 0 ? 
                    <BottomNavigationAction label={translationContainer.GetTranslation('tables', 'people', 'People')} icon={<GroupsIcon />} />
                : null }
                {showRegion ?
                    <BottomNavigationAction label={translationContainer.GetTranslation('tables', 'my-region', 'My Region')} icon={<TravelExploreIcon />} />
                : null }

                </BottomNavigation>
            </Paper>
        </Box>
      )
}

/*
                <BottomNavigationAction label="Groups" icon={<GroupWorkIcon />} />
                <BottomNavigationAction label="Reports" icon={<AssessmentIcon />} />
*/

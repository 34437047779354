import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import ADPostList from './ADPostList/ADPostList';
import AddContentPost from './AddContentPost';
import ViewContentPost from './ViewContentPost';
import Editor from 'react-simple-wysiwyg';
import sanitizeHtml from 'sanitize-html';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Modal from '@mui/material/Modal';


export default function ViewContent(props) {
    
    const [filterParams, setFilterParams] = useState({category: 'resource', type: 'all', language: 'en', title: ''});
    const [contentList, setContentList] = useState([]);
    const [addContentModalOpen, setAddContentModalOpen] = useState(false);
    const [viewContentModalID, setViewContentModalID] = useState(-1);
    const aldersgateContext = useContext(AldersgateContext);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const handleCategoryChange = (event) => {

        const { value } = event.target;

        setFilterParams({...filterParams, category: value});
    }

    const handleTypeChange = (event) => {
        const { value } = event.target;

        setFilterParams({...filterParams, type: value});
    }

    const handleLanguageChange = (event) => {
        const { value } = event.target;

        setFilterParams({...filterParams, language: value});
    }

    const handleValueChange = (event) => {
        const { id, value } = event.target;

        setFilterParams({...filterParams, [id]: value});
    }
    

    const handleSearch = () => {
        console.log('Search: ', filterParams);

        //post to /cqry
        let queryString = 'category=' + filterParams.category + '&type=' + filterParams.type + '&language=' + filterParams.language + '&title=' + filterParams.title;

        aldersgateContext.PostToAPI('content/cqry', filterParams, queryString, 1).then((success, err) => {
            if(success) {
                console.log("Success: " + JSON.stringify(success, null, 2));
                setContentList(ProcessContent(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        });
    }

    const ProcessContent = (content) => {
        let contentList = [];

        let index = 0;
        content.forEach(item => {
            contentList.push({
                ...item, id: index++
            });
        });

        return contentList;
    }

    const OnContentView = (contentID) => {
        console.log('View Content: ', contentID);
        setViewContentModalID(contentID);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{display:'flex', direction:'row', width:'100%', gap:'10px'}} mb={1}>
                    <Button variant="contained" size="small" color="primary" onClick={()=>{setAddContentModalOpen(true)}} startIcon={<LibraryAddIcon />}>Add New Content</Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display:'flex', direction:'row', width:'100%', gap:'10px'}}>
                <FormControl fullWidth>
                    <InputLabel id="catLable">Category</InputLabel>
                    <Select
                    labelId="catLable"
                    id="category"
                    value={filterParams.category}
                    label="Category"
                    onChange={handleCategoryChange}
                    size='small'
                    >
                    <MenuItem value="resource">Resource</MenuItem>
                    <MenuItem value="Module 1">Module 1</MenuItem>
                    <MenuItem value="Module 2">Module 2</MenuItem>
                    <MenuItem value="Journey">Journey</MenuItem>
                    <MenuItem value="Mobilization">Mobilization</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel id="typeLable">Type</InputLabel>
                    <Select
                    labelId="typeLable"
                    id="type"
                    value={filterParams.type}
                    label="Type"
                    onChange={handleTypeChange}
                    size='small'
                    >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="announcement">Announcement</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="article">Article</MenuItem>
                    <MenuItem value="podcast">Podcast</MenuItem>
                    <MenuItem value="link">Link</MenuItem>
                    <MenuItem value="pdf">PDF</MenuItem>
                    <MenuItem value="doc">DOC</MenuItem>
                    <MenuItem value="ppt">PPT</MenuItem>
                    </Select>
                </FormControl>

                <FormControl id="language" fullWidth>
                    <InputLabel id="langLable">Language</InputLabel>
                    <Select
                    labelId="langLable"
                    id="language"
                    value={filterParams.language}
                    label="Language"
                    onChange={handleLanguageChange}
                    size='small'
                    >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="fr">French</MenuItem>
                    <MenuItem value="pt">Portuguese</MenuItem>
                    <MenuItem value="es">Spanish</MenuItem>
                    <MenuItem value="ru">Russian</MenuItem>
                    </Select>
                </FormControl>
                
                <FormControl fullWidth>
                    <TextField id="title" label="Title" size="small" onChange={handleValueChange} />
                </FormControl>
                <Button variant="contained" size="small" onClick={handleSearch} color="primary">Search</Button>
                </Box>
            </Grid>
            

            <Grid item xs={12} mb={5}>
                <ADPostList contentList={contentList} onRowClick={OnContentView} />
            </Grid>

            <Modal
                open={addContentModalOpen}
                onClose={()=>{setAddContentModalOpen(false)}}
                aria-labelledby="modal-add-post"
                aria-describedby="add-a-new-post"
                >
                    <Box sx={modalStyle}>
                        <AddContentPost closeAddContent={()=>{setAddContentModalOpen(false)}} filter={filterParams} />
                    </Box>
            </Modal>
            <Modal
                open={viewContentModalID >= 0}
                onClose={()=>{setViewContentModalID(-1)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <ViewContentPost content={contentList[viewContentModalID]} />
                    </Box>
            </Modal>
        </Grid>
    );
}

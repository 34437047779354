import React, {useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TranslationText from '../TranslationText';
import useMediaQuery from '@mui/material/useMediaQuery';
import ViewParticipant from './ViewParticipant';
import AddParticipant from './AddParticipant';
import DPeopleList from './DPeopleList/DPeopleList';
//import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconedTextButton from '../IconedTextButton';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import AddIcon from '@mui/icons-material/Add';


export default function ParticipantsDataGrid(props) {
    const {updateInformation } = props;
    const { participants, eventID, signupID} = props.eventData;

    const history = useHistory();

    const [formDisabled, setFormDisabled] = useState(true);
    const [addPersonDisabled, setAddPersonDisabled] = useState(true);
    const [formData, setFormData] = useState({id:0, adID:"", fName:"", lName:"", alias:"", email:"", phoneNumber:"", dropped:false, completed:false, language:"en", country:"", state:"", city:"", });
    const [value, setValue] = useState(0);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const columns = [
        { field: 'fName', headerName: 'First Name', flex: 1,editable: false },
        { field: 'lName', headerName: 'Last Name', flex: 1,editable: false },
        { field: 'email', headerName: 'Email', flex: 1,editable: false }
    ];

    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: '#ffffff',
      border: '2px solid #000',
      overflow:'scroll',
      boxShadow: 24,
      p: 4,
      width:(smMatches) ? '50%' : '85%',
      height: '100%'
    };

    const addParticipantModalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: '#ffffff',
      border: '2px solid #000',
      overflow:'scroll',
      boxShadow: 24,
      p: 4,
      width:(smMatches) ? '50%' : '85%',
      height: '100%'
    };

    // function CustomToolbar() {
    //     return (
    //       <GridToolbarContainer>
    //         <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    //       </GridToolbarContainer>
    //     );
    //   }

    function handleAddParticipant(event, name) {
        setAddPersonDisabled(false);
      }

      function handleSignupForm(event, name) {
        history.push("/reg/" + signupID);
      }

    const handleCloseForm = () => {
      setFormDisabled(true);
    }

    const closeAddParticipantForm = () => {
      setAddPersonDisabled(true);
    }


    
      const EditParticipantDataForm = (disableForm) => {
          return (
            <div>
              <Modal
                  open={!disableForm.disableForm}
                  onClose={handleCloseForm}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{height:'100%', width:'100%'}}
              >
                  <Box sx={modalStyle}>
                    <ViewParticipant data={formData} closeForm={handleCloseForm} updateInformation={updateInformation} />
                  </Box>
              </Modal></div>
          );
    }

    const AddParticipantDataForm = (disableForm) => {
      return (
        <div>
          <Modal
              open={!disableForm.disableForm}
              onClose={closeAddParticipantForm}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <Box sx={addParticipantModalStyle}>
              <AddParticipant closeForm={closeAddParticipantForm} updateInformation={updateInformation} eventID={eventID}/>
            </Box>
          </Modal></div>
      );
}

    const handleRowClick = (params) => {
      //params.row.id
      //print out params object
      setFormData(params);
      setFormDisabled(false);
    };
    
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Paper elevation={1}>
                <DPeopleList onRowClick={handleRowClick} peopleListData={participants} signupID={signupID} eventID={eventID} />
            </Paper>
            <EditParticipantDataForm disableForm={formDisabled}/>
            <AddParticipantDataForm disableForm={addPersonDisabled}/>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Box mr={2} sx={{display:'flex', flexDirection:'row', alignContent:'flex-start', alignItems:'center', justifyContent:'flex-start'}} onClick={handleAddParticipant}><Box><IconedTextButton  icon={<Avatar><AddIcon /></Avatar>} /></Box><Box sx={{alignSelf:'center'}}><Typography align="left" variant="body2" color="action"><TranslationText page='tables' dbKey='add-participant'>Add Participant</TranslationText></Typography></Box></Box>
              <Box ml={2} sx={{display:'flex', flexDirection:'row', alignContent:'flex-start', alignItems:'center', justifyContent:'flex-start'}} onClick={handleSignupForm}><Box><IconedTextButton  icon={<Avatar><ViewAgendaIcon /></Avatar>} /></Box><Box sx={{alignSelf:'center'}}><Typography align="left" variant="body2" color="action"><TranslationText page='tables' dbKey='sign-up-form'>Sign up form</TranslationText></Typography></Box></Box>
            </Box>      
        </Box>
    )

}


/*
<DataGrid onRowClick={handleRowClick} rows={participants} columns={columns} 
                    rowsPerPageOptions={[25, 50, 75]}
                    pagination
                    autoHeight
                    components={{
                        Toolbar: CustomToolbar,
                      }}
                />*/
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


export default function RDCountryListRow(props) {
    const { rowItemData } = props;

    const history = useHistory();

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    return (
        <Box onClick={() => rowItemData.onRowClick(rowItemData.id)}>
            <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                <Grid item xs={4} sm={4}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {rowItemData.name}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                     
                <Grid item xs={4} sm={4} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {rowItemData?.eventCount || 0}
                    </Box>
                </Grid>


                <Grid item xs={4} sm={4} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {rowItemData?.trainedCount || 0}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
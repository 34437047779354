import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import CircularProgress from '@mui/material/CircularProgress';

export default function RDLeadersTypeGraph(props) {
    const { groupIDs, filterData } = props;

    const [graphData, setGraphData] = useState({titleData:["HMJ", "Module 1", "Module 2", "Summit"], countData:[0, 0, 0, 0]});
    const [receivedData, setReceivedData] = useState(false);

    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetEventsCount();
    }, [groupIDs, filterData]);

    const GetEventsCount = () => {
        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(filterData?.startDate !== undefined && filterData?.startDate !== null)
            query += '&startingDate=' + filterData?.startDate?.toISOString();

        if(filterData?.endDate !== undefined && filterData?.endDate !== null)
            query += '&endingDate=' + filterData?.endDate.toISOString();
        
        aldersgateContext.GetFromAPI('stats/r/count/leadersxtypes', query, 1).then((success, err) => {
            if(success) {

                //console.log("Success: " + JSON.stringify(success));
                ProcessData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ProcessData = (data) => {
        if(data === undefined || data === null)
            return;

        let tempTitleData = [];
        let tempCountData = [];

        data.forEach(element => {
            switch(element.type) {
                case "Journey":
                    tempTitleData.push("HMJ");
                    tempCountData.push(element.count);
                    break;
                case "Module 1":
                    tempTitleData.push("Module 1");
                    tempCountData.push(element.count);
                    break;
                case "Module 2":
                    tempTitleData.push("Module 2");
                    tempCountData.push(element.count);
                    break;
                case "Summit":
                    tempTitleData.push("Summit");
                    tempCountData.push(element.count);
                    break;
                default:
                    break;
            }
        });

        setGraphData({titleData:tempTitleData, countData:tempCountData});
        setReceivedData(true);
    }

    return(
        <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>
            <Paper>
                <Box p={1}>
                    <Box sx={{height:'50px'}}>
                        <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                            Leaders Trained by Type
                        </Typography>
                    </Box>

                    <Box sx={{height:'300px'}}>
                        {receivedData ? <BarChart
                            xAxis={[
                                {
                                id: 'barCategories',
                                data: graphData?.titleData,
                                scaleType: 'band',
                                },
                            ]}
                            series={[
                                {
                                data: graphData?.countData,
                                },
                            ]}
                            height={300}
                        />
                        
                        : <Box sx={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>}
                    
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
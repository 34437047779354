import React, {useEffect, useState} from  'react';
import FilterView from './FilterView';
import RDDateRange from './Elements/RDDateRange';
import RDTopNumbersLong from './Elements/RDTopNumbersLong';
import RDHistEventGraph from './Elements/RDHistEventGraph';
import RDHistLeaderGraph from './Elements/RDHistLeaderGraph';
import RDEventTypeGraph from './Elements/RDEventTypeGraph';
import RDLdrTrainedMethod from './Elements/RDLdrTrainedMethod';
import RDEventsMethod from './Elements/RDEventsMethod';
import RDLeadersTypeGraph from './Elements/RDLeadersTypeGraph';
import RDETeams from './Elements/RDETeams';
import RDCountries from './Elements/RDCountries';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DateRangeIcon from '@mui/icons-material/DateRange';

export default function RDashAnalytics(props) {
    const{groupIDs} = props;

    //first day this year
    let firstDay = new Date(new Date().getFullYear(), 0, 1);
    firstDay.setFullYear(firstDay.getFullYear() - 1);
    //first day 5 years ago
    let firstDay5YearsAgo = new Date(new Date().getFullYear(), 0, 1);
    firstDay5YearsAgo.setFullYear(firstDay5YearsAgo.getFullYear() - 1);

    //last day this year
    const lastDay = new Date(new Date().getFullYear(), 11, 31);

    const [filterData, setFilterData] = useState({groupIDs: groupIDs, startDate: firstDay, endDate: lastDay});
    const [filterHidden, setFilterHidden] = useState(true);

    const showFilterOnClick = () => {
        setFilterHidden(false);
    }

    return (
        <Box sx={{display:'flex', flexDirection:'column', bgcolor: '#eeeeee', p: 1, gap: 1}}>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{width:'100%'}}>
                        <Box sx={{position:'relative'}}>
                            {!filterHidden ? <Box><FilterView filterData={filterData} setFilterData={setFilterData} setHidden={setFilterHidden} /></Box> : null}
                            <Box> <RDDateRange filterData={{startDate: filterData.startDate, endDate: filterData.endDate}} /></Box>
                            <Box><RDTopNumbersLong groupIDs={groupIDs} headerText='Registered Events' filterData={filterData} /></Box>
                            <Box onClick={()=>{showFilterOnClick()}} sx={{position:'absolute', right:0, top:0}}><DateRangeIcon sx={{color:"#555555"}} /></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6} sx={{width:'100%'}}>
                        <RDEventTypeGraph groupIDs={groupIDs} filterData={filterData} startingDate={filterData.startDate} endingDate={lastDay} />
                    </Grid>
                    <Grid item sm={12} md={6} sx={{width:'100%'}}>
                        <RDLeadersTypeGraph groupIDs={groupIDs} filterData={filterData} startingDate={firstDay5YearsAgo} endingDate={lastDay} />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6} sx={{width:'100%'}}>
                        <RDHistEventGraph groupIDs={groupIDs} filterData={filterData} startingDate={firstDay5YearsAgo} endingDate={lastDay} />
                    </Grid>
                    <Grid item sm={12} md={6} sx={{width:'100%'}}>
                        <RDHistLeaderGraph groupIDs={groupIDs} filterData={filterData} startingDate={firstDay5YearsAgo} endingDate={lastDay} />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                        <RDEventsMethod groupIDs={groupIDs} filterData={filterData} startingDate={firstDay5YearsAgo} endingDate={lastDay} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <RDLdrTrainedMethod groupIDs={groupIDs} filterData={filterData} startingDate={firstDay5YearsAgo} endingDate={lastDay} />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6} sx={{width:'100%'}}>
                        <Box>
                            <RDETeams groupIDs={groupIDs} filterData={filterData} startingDate={firstDay5YearsAgo} endingDate={lastDay} />
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={6} sx={{width:'100%'}}>
                        <RDCountries groupIDs={groupIDs} filterData={filterData} startingDate={firstDay5YearsAgo} endingDate={lastDay} />
                    </Grid>
                </Grid>
            </Box>

        </Box>
    );



}
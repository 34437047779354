import React, {useEffect, useState, useContext} from  'react';
import NavBreadcrumbs from "../components/NavBreadcrumbs";
import AuthContext from '../context/AuthContext';
import { AldersgateContext } from '../context/aldersgate';
import TranslationContainer from '../localization/TranslationContainer';
import TranslationText from '../components/TranslationText';
import EventFeed from '../components/pEventView/EventFeed';
import EventTabs from '../components/pEventView/EventTabs';
import EventResources from '../components/pEventView/EventResources';
import EventAssignments from '../components/pEventView/EventAssignments';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';




export default function ParticipantEventView(props) {

    //get event ID from props
    const { id } = props.match.params;

    const [eventData, setEventData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tabValue, setTabValue] = React.useState(0);
    const aldersgateContext = useContext(AldersgateContext);
    const authContext = useContext(AuthContext);

    const translationContainer = new TranslationContainer();

    useEffect(() => {

        console.log("Auth Context: " + JSON.stringify(authContext, null, 2));

        //if we are logged in
        if(authContext?.accountState?.loggedIn) {

            //get the event information
            aldersgateContext.GetFromAPI('event/' + id + '/pview', null, -1).then((success, err) => {

                if(success) {
                    setIsLoading(false);
                    setEventData(success);
                    console.log("Success: " + JSON.stringify(success, null, 2));
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });
        }
        
    }, [authContext]);

    const PrettyDate = (date) => {
        let d = new Date(date);
        return d.toLocaleDateString();
    }

    const DisplayPage = () => {
        switch(tabValue) {
            case 0:
                return <EventFeed signupID ={id} eventData={eventData}/>;
            case 1:
                return <EventResources signupID ={id} />;
            case 2:
                return <EventAssignments signupID ={id} />;
    
            default:
                return <EventFeed signupID ={id} />;
        }
    }

    return (
        <Box m={1.5} bgcolor="background.paper" >
            <Grid container >
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}></Grid>
                
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>

                    <NavBreadcrumbs crumbs={[{href:"/dashboard", text:translationContainer.GetTranslation('tables', 'dashboard', 'Dashboard')}]} currentPage={translationContainer.GetTranslation('tables', 'training', 'Training')} />

                    
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h1"  m={0}>
                                    <TranslationText page='tables' dbKey='training-portal'>Training Portal</TranslationText>
                                </Typography>
                            </Grid>

                            
                            <Grid item xs={12}>
                                <EventTabs changeTabCallback={setTabValue}/>
                                <Paper variant="outlined" sx={{p:2}}>
                                    {isLoading ?  <Box><CircularProgress /></Box> : (
                                        <DisplayPage />
                                    )}
                                </Paper>
                            </Grid>

                        </Grid>

                </Grid>

                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}></Grid>

            </Grid>
            <Box sx={{ height: 55 }} />
            
        </Box>
    );
}
import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
//import { BarChart } from '@mui/x-charts/BarChart';
import CircularProgress from '@mui/material/CircularProgress';
import { LineChart } from '@mui/x-charts/LineChart';

export default function RDHistLeaderGraph(props) {
    const {filterData} = props;
    const { groupIDs } = props;

    const [graphData, setGraphData] = useState({titleData:[], countData:[]});
    const [receivedData, setReceivedData] = useState(false);

    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetEventsCount();
    }, [groupIDs, filterData]);

    const GetEventsCount = () => {
        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(filterData?.startDate !== undefined && filterData?.startDate !== null)
            query += '&startingDate=' + filterData?.startDate.toISOString();

        if(filterData?.endDate !== undefined && filterData?.endDate !== null)
            query += '&endingDate=' + filterData?.endDate.toISOString();

        let years = 4;

        //get the number of years between start and end date
        if(filterData?.startDate !== undefined && filterData?.startDate !== null && filterData?.endDate !== undefined && filterData?.endDate !== null) {
            let startYear = filterData?.startDate.getFullYear();
            let endYear = filterData?.endDate.getFullYear();
            years = endYear - startYear;
        }

        if(years < 4)
            years = 4;

        query += '&years=' + years;
        
        aldersgateContext.GetFromAPI('stats/r/count/histleadercount', query, 1).then((success, err) => {
            if(success) {
                //console.log("Success: " + JSON.stringify(success));
                ProcessData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ProcessData = (data) => {
        if(data === undefined || data === null)
            return;

        if(data.length === 0) {

            setGraphData({titleData:[], countData:[]});
            setReceivedData(false);
            return;
        }

        let tempTitleData = [];
        let tempCountData = [];

        data.forEach(element => {
            tempTitleData.push(element.year);
            tempCountData.push(element.count);
        });

        setGraphData({titleData:tempTitleData, countData:tempCountData});
        setReceivedData(true);
    }

    return(
        <Paper>
            <Box p={1}>
                <Box sx={{height:'50px'}}>
                    <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                        Leaders Trained
                    </Typography>
                </Box>
                <Box sx={{height:'300px'}}>
                    {receivedData ? <LineChart
                        xAxis={[
                            {
                            id: 'barCategories',
                            data: graphData?.titleData,
                            scaleType: 'point',
                            },
                        ]}
                        series={[
                            {
                            data: graphData?.countData, area: true, showMark: true
                            },
                        ]}
                        height={300}
                    />
                    
                    : <Box sx={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>}
                    
                </Box>
            </Box>
        </Paper>
    );
}
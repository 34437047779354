import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import RDTeamsView from './RDETeams/RDTeamsView';
import RDCountriesView from './RDCountries/RDCountriesView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

export default function RDCountires(props) {

    const {filterData} = props;
    const { groupIDs, startingDate, endingDate } = props;

    const [teamsData, setTeamsData] = useState([]);
    const [receivedData, setReceivedData] = useState(false);
    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetTeams();
    }, [groupIDs, startingDate, endingDate]);

    const GetTeams = () => {

        console.log("GroupIDs: " + JSON.stringify(groupIDs));

        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(startingDate !== undefined && startingDate !== null)
            query += '&startingDate=' + startingDate.toISOString();

        if(endingDate !== undefined && endingDate !== null)
            query += '&endingDate=' + endingDate.toISOString();

        console.log("Query: " + query);
        
        aldersgateContext.GetFromAPI('stats/r/count/countries', query, 1).then((success, err) => {
            if(success) {

                console.log("country Success: " + JSON.stringify(success));
                ProcessData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ProcessData = (data) => {
        if(data === undefined || data === null)
            return;

        let teamsList = [];
        let idCount = 0;
        data.countries.forEach(element => {
            let id = idCount++;
            element.id = id;
            teamsList.push(element);
        });

        //order by name - ascending
        teamsList.sort((a, b) => (a.name > b.name) ? 1 : -1);

        setTeamsData(teamsList);
        setReceivedData(true);
    }


    return(
        <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>
            <Paper>
                <Box p={1}>
                    <Box sx={{height:'50px'}}>
                        <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                            Countries
                        </Typography>
                    </Box>
                    <Box sx={{height:'300px'}}>
                        {receivedData ? <RDCountriesView listData={teamsData} /> : <Box sx={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
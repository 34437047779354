import React from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function IconLabelTabs(props) {
  const { changeTabCallback } = props;

  const [value, setValue] = React.useState(0);

  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

  const translationContainer = new TranslationContainer();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(changeTabCallback !== undefined)
      changeTabCallback(newValue);
  };

  return (
      <Box>
        {mdMatches ? 
            <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="Tabs for viewing event data"
          >
          <Tab icon={<AssignmentIndIcon aria-label="View participants" />} label={<Box>{translationContainer.GetTranslation("tables", "participants", "Students")}</Box>} />
          <Tab icon={<SupervisedUserCircleIcon aria-label="View Facilitators" />} label={translationContainer.GetTranslation("tables", "facilitators", "Facilitators")} />
          <Tab icon={<ListAltIcon aria-label="View Sessions" />} label={translationContainer.GetTranslation("tables", "sessions", "Sessions")} />
          <Tab icon={<CloudDownloadIcon aria-label="View Resources" />} label={translationContainer.GetTranslation("tables", "resources", "Resources")} />
          </Tabs>
         : 
          <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="Tabs for viewing event data"
            >
            <Tab icon={<AssignmentIndIcon aria-label="View participants" />} label={<Typography fontSize={11}>{translationContainer.GetTranslation("tables", "participants", "Students")}</Typography>} />
            <Tab icon={<SupervisedUserCircleIcon aria-label="View Facilitators" />} label={<Typography fontSize={11}>{translationContainer.GetTranslation("tables", "facilitators", "Facilitators")}</Typography>} />
            <Tab icon={<ListAltIcon aria-label="View Sessions" />} label={<Typography fontSize={11}>{translationContainer.GetTranslation("tables", "sessions", "Sessions")}</Typography>} />
            <Tab icon={<CloudDownloadIcon aria-label="View Resources" />} label={<Typography fontSize={11}>{translationContainer.GetTranslation("tables", "resources", "Resources")}</Typography>} />
            </Tabs>
         }
         </Box>
      
  );
}

import React, {useEffect, useState} from  'react';
import RDDateRange from './RDDateRange';
import RDTopNumbersLong from './RDTopNumbersLong';
import RDHistEventGraph from './RDHistEventGraph';
import RDHistLeaderGraph from './RDHistLeaderGraph';
import RDEventTypeGraph from './RDEventTypeGraph';
import RDLdrTrainedMethod from './RDLdrTrainedMethod';
import RDEventsMethod from './RDEventsMethod';
import RDLeadersTypeGraph from './RDLeadersTypeGraph';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DateRangeIcon from '@mui/icons-material/DateRange';

export default function SFTModal(props) {
    const {filterData} = props;
    const{ groupIDs, closeModal, name } = props;


    return (
        <Box sx={{display:'flex', flexDirection:'column', bgcolor: '#eeeeee', p: 1, gap: 1, height:'100%'}}>
            <Box>
                <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                    {name}
                </Typography>
            </Box>

            <Box sx={{display:'flex', flexDirection:'column', bgcolor: '#eeeeee', p: 1, gap: 1, height:'100%', overflow: 'scroll'}}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{width:'100%'}}>
                            <Box>
                                <Box> <RDDateRange filterData={{startDate: filterData?.startDate, endDate: filterData?.endDate}} /></Box>
                                <Box><RDTopNumbersLong groupIDs={groupIDs} headerText='Registered Events' filterData={filterData} /></Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6} sx={{width:'100%'}}>
                            <RDEventTypeGraph groupIDs={groupIDs} filterData={filterData} />
                        </Grid>
                        <Grid item sm={12} md={6} sx={{width:'100%'}}>
                            <RDLeadersTypeGraph groupIDs={groupIDs} filterData={filterData} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6} sx={{width:'100%'}}>
                            <RDHistEventGraph groupIDs={groupIDs} filterData={filterData} />
                        </Grid>
                        <Grid item sm={12} md={6} sx={{width:'100%'}}>
                            <RDHistLeaderGraph groupIDs={groupIDs} filterData={filterData} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <RDEventsMethod groupIDs={groupIDs} filterData={filterData} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <RDLdrTrainedMethod groupIDs={groupIDs} filterData={filterData} />
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </Box>
    );



}
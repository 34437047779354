import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function RDDateRange(props) {
    const {filterData} = props;

    const [dateRange, setDateRange] = useState({start: new Date(), end: new Date()});

    useEffect(() => {
        if(filterData != null){
            let start = new Date(filterData.startDate);
            let end = new Date(filterData.endDate);

            setDateRange({start: start, end: end});
        }
    }, [filterData]);

    return(

        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', verticalAlign:'center', width:'100%', height:'55px'}}>
            <h5 style={{ fontSize: '25px', color: 'grey' }}>
                {dateRange.start.toLocaleDateString()} - {dateRange.end.toLocaleDateString()}
            </h5>
        </Box>
    );
}
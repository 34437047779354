import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

export default function FilterView(props) {
    const {filterData, setFilterData, setHidden} = props;

    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);

    useEffect(() => {
        if(filterData != null){
            let start = new Date(filterData.startDate);
            let end = new Date(filterData.endDate);

            setStartDate(start);
            setEndDate(end);
        }
    }, [filterData]);


    const handleOkay = () => {
        console.log("Start Date: " + startDate);
        console.log("End Date: " + endDate);
        
        setFilterData(prevState => {
            return {...prevState, ['startDate']: startDate, ['endDate']: endDate};
        });


        setHidden(true);
    }


    return (
        <Box>
            <FormControl sx={{ m: 1, minWidth: 100, height:10 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                    id="start-date-filter-select"
                    label="Start Date"
                    size="small"
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                    />
                </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 100 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                id="end-date-filter-select"
                label="End Date"
                size="small"
                value={endDate}
                onChange={(newValue) => {
                    setEndDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
                />
            </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Button variant="contained" size="default" onClick={handleOkay}>Okay</Button>
            </FormControl>
        </Box>
    );
}
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function UserEmailStatus(props) {

    const { elementTitle, elementData, OnElementChange } = props;

    const [fieldData, setFieldData] = useState(null);
    const [lastValue, setLastValue] = useState(null)
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        setFieldData(elementData);
    }, [elementData]);

    const handelStartEdit = () => {
        if(fieldData === null || fieldData === undefined || fieldData === "") {
            return;
        }

        setLastValue(fieldData);
        setShowEdit(true);
    }

    const handleSetFalse = () => {
        if(lastValue === null || lastValue === undefined || lastValue === "" || lastValue === false) {
            return;
        }

        setFieldData(false);
        
        OnElementChange(false);

        setShowEdit(false);
    }

    const handleSetTrue = () => {
        if(lastValue === null || lastValue === undefined || lastValue === "" || lastValue === true) {
            return;
        }

        setFieldData(true);

        OnElementChange(true);
        setShowEdit(false);
    }

    const DisplayStatus = () => {
        if(fieldData === null || fieldData === undefined || fieldData === "") {
            return "No Email";
        } else {
            return fieldData ? "Yes" : "No";
        }
    }


    if(showEdit) {
        return (
            <Stack direction="row" justifyContent="flex-start">
                <Typography variant="body1" component="p" onClick={() => {handelStartEdit()}}>
                    {elementTitle} <DisplayStatus />
                </Typography>
                <IconButton aria-label="Cancel" onClick={() => {handleSetFalse()}}><CancelIcon color='error' /></IconButton>
                <IconButton aria-label="Save" onClick={() => {handleSetTrue()}}><AddCircleIcon color='success' /></IconButton>
            </Stack>
        );
    } else {
        return (
            <Typography variant="body1" component="p" onClick={() => {handelStartEdit()}}>
                {elementTitle} <DisplayStatus />
            </Typography>
        );
    }
}
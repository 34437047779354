import React, {useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import TranslationText from "../TranslationText";
import ResourcesFilter from "./ResourcesFilter";
import AContentList from "./AContentList/AContentList";
import { AldersgateContext } from "../../context/aldersgate";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';


    export default function ResourcesView(props) {
        const { category, showHeader } = props;
        const [loadingContent, setLoadingContent] = useState(false);
        const [contentList, setContentList] = useState([]);
        const aldersgateContext = useContext(AldersgateContext);

        const history = useHistory();

        useEffect(() => {
            //SearchForContent({category: category, type: 'all', language: 'en', title: ''});
        }, [category]);

        const SearchForContent = (searchFilterData) => {
            setLoadingContent(true);

            let queryString = 'category=' + searchFilterData.category + '&type=' + searchFilterData.type + '&language=' + searchFilterData.language + '&title=' + searchFilterData.title;

            aldersgateContext.PostToAPI('content/cqry', searchFilterData, queryString, 1).then((success, err) => {
                setLoadingContent(false);
                if(success) {
                    setContentList(ProcessContent(success));
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
                setLoadingContent(false);
            });
        }

        const ProcessContent = (content) => {
            let contentList = [];
    
            let index = 0;
            content.forEach(item => {
                contentList.push({
                    ...item, id: index++
                });
            });

            //sort by priority
            contentList.sort((a, b) => {
                return a.priority - b.priority;
            });
    
            return contentList;
        }

        const OnRowClick = function(resourceID) {
            //get the resource from contentList
            let resource = contentList.find(item => item.id === resourceID);
            console.log("Resource: " + JSON.stringify(resource, null, 2));
            if(resource !== undefined && resource !== null) {
                window.open(resource.link, '_blank');
            }
        }

        const ToDashboard = () => {
            history.push('/dashboard');
        }

        return (
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', gap:'10px', width:'100%'}}>
                <Box>
                    <Button onClick={ToDashboard} startIcon={<AutoAwesomeMosaicIcon />}><TranslationText page='tables' dbKey='dashboard'>Dashboard</TranslationText></Button>
                </Box>
                <Box sx={{width:'100%'}}>
                {showHeader ? (
                    <Box>
                        <Typography variant="h2"><TranslationText page='tables' dbKey='resources'>Resources</TranslationText></Typography>
                    </Box>
                ) : null}
                <Box mt={2}><ResourcesFilter category={category} onSearch={SearchForContent} disableCategory={!showHeader} /></Box>
                {loadingContent ? (
                    <Box mt={4} mb={4} display="flex" justifyContent="center"><CircularProgress /></Box>
                ) : <AContentList contentList={contentList} OnRowClick={OnRowClick} />}
                </Box>
            </Box>
      )
  }
import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import CircularProgress from '@mui/material/CircularProgress';

export default function RDLdrTrainedMethod(props) {
    const { groupIDs, filterData } = props;

    const [graphData, setGraphData] = useState({titleData:["2022", "2023", "2024", "2025"], seriesData:[{ data: [0, 0, 0, 0], label:'In-Person' }, { data: [0, 0, 0, 0], label:'Online' }, { data: [0, 0, 0, 0], label:'Hybrid' }]});
   
    // const [titleData, setTitleData] = useState(['2022', '2023', '2024', '2025']);
    // const [countData, setCountData] = useState([{ data: [0, 0, 0, 0], label:'In-Person' }, { data: [0, 0, 0, 0], label:'Online' }, { data: [0, 0, 0, 0], label:'Hybrid' }]);
    const [receivedData, setReceivedData] = useState(false);

    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetEventsCount();
    }, [groupIDs, filterData]);

    const GetEventsCount = () => {
        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(filterData?.startDate !== undefined && filterData?.startDate !== null)
            query += '&startingDate=' + filterData?.startDate?.toISOString();

        if(filterData?.endDate !== undefined && filterData?.endDate !== null)
            query += '&endingDate=' + filterData?.endDate?.toISOString();
    
        let years = 4;

        //get the number of years between start and end date
        if(filterData?.startDate !== undefined && filterData?.startDate !== null && filterData?.endDate !== undefined && filterData?.endDate !== null) {
            let startYear = filterData?.startDate.getFullYear();
            let endYear = filterData?.endDate.getFullYear();
            years = endYear - startYear;
        }

        if(years < 4)
            years = 4;

        query += '&years=' + years;
        
        aldersgateContext.GetFromAPI('stats/r/count/histleadersxtypes', query, 1).then((success, err) => {
            if(success) {

                console.log("Success: " + JSON.stringify(success));
                ProcessData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ProcessData = (data) => {
        if(data === undefined || data === null)
            return;

        let tempTitleData = [];
        let methodData = new Map();
        data.forEach(element => {
            
            tempTitleData.push(element.year);

            element.data.forEach(method => {
                if(methodData.has(method.type)) {
                    let temp = methodData.get(method.type);
                    temp.push(method.count);
                    methodData.set(method.type, temp);
                } else {
                    methodData.set(method.type, [method.count]);
                }
            });
            
        });

        let tempCountData = [];
        methodData.forEach((value, key) => {
            tempCountData.push({data: value, label:key});
        });

        setGraphData({titleData:tempTitleData, seriesData:tempCountData});

        // setTitleData(tempTitleData);
        // setCountData(tempCountData);
        setReceivedData(true);
    }
    

    return(
        <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>
            <Paper>
                <Box p={1}>
                    <Box sx={{height:'50px'}}>
                        <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                            Leaders Trained by Method
                        </Typography>
                    </Box>

                    <Box sx={{height:'300px'}}>
                        {receivedData ? <BarChart
                                xAxis={[
                                    {
                                    id: 'barCategories',
                                    data: graphData?.titleData,
                                    scaleType: 'band',
                                    },
                                ]}
                                series={
                                    graphData?.seriesData
                                }
                                height={300}
                            />
                        
                        : <Box sx={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>}
                    
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
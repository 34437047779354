import React, {useEffect, useState, useContext} from  'react';
import TranslationText from '../TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { AldersgateContext } from '../../context/aldersgate';
import AuthContext from '../../context/AuthContext';
import AddEventFeedPost from './AddEventFeedPost';
import FAnnouncementElement from '../FeedElements/FAnnouncementElement';
import FVideoElement from '../FeedElements/FVideoElement';
import FCallInviteElement from '../FeedElements/FCallInviteElement';
import {QRCodeSVG} from 'qrcode.react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';


export default function EventFeed(props) {
    const { signupID, eventData, isAdmin } = props;

    const [feedItems, setFeedItems] = useState([]);
    const [newPostOpen, setNewPostOpen] = useState(false);
    const [ticketModalOpen, setTicketModalOpen] = useState(false);

    const aldersgateContext = useContext(AldersgateContext);
    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));
    const xsMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const translationContainer = new TranslationContainer();
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

    let rTheme = createTheme();
    rTheme = responsiveFontSizes(rTheme);

    rTheme.typography.p = {
        fontSize: '1.0rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.7rem',
        },
        [theme.breakpoints.up('xs')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.0rem',
        },
      };
      
      

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        overflow:'scroll',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '50%' : '85%',
    };

    const ticketStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        overflow:'scroll',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '40%' : '85%'
    };

    useEffect(() => {
        let mounted = true;
        if(signupID !== undefined && signupID !== null) {
            aldersgateContext.GetFromAPI('event/' + signupID + '/pfeed', null, -1).then((success, err) => {
                if(!mounted) {
                    return null;
                }
    
                if(success) {
                    setFeedItems(SortFeedItems(success));
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });
        }

        return () => mounted = false;

    }, [signupID]);

    const SortFeedItems = (feedItems) => {
        let priorityItems = [];
        let normalItems = [];

        feedItems.forEach((item) => {
            let today = new Date();
            //7 days prior
            let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

            if(item.priority < 50 || new Date(item.date) < lastWeek) {
                priorityItems.push(item);
            } else {
                normalItems.push(item);
            }
        });

        priorityItems.sort((a, b) => {
            return a.priority - b.priority;
        });

        normalItems.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });

        return priorityItems.concat(normalItems);
    }

    const BuildFeed = (feedData) => {
        let feed = [];

        if(!feedData) {
            return null;
        }

        let elementID = 0;
        feedData.forEach((item) => {
            switch(item.type) {
                case 'announcement':
                    feed.push(<FAnnouncementElement key={elementID} elementData={item} />);
                    break;
                // case 'event':
                //     feed.push(EventElement(item));
                //     break;
                case 'video':
                    feed.push(<FVideoElement key={elementID} elementData={item} />);
                    break;
                // case 'image':
                //     feed.push(ImageElement(item));
                //     break;
                case 'callinvite':
                    feed.push(<FCallInviteElement key={elementID} elementData={item} />);
                    break;
                default:
                    break;
            }

            elementID++;
        });

        return feed;
    }

    //pretty date format
    const GetPrettyDate = (date) => {
        return translationContainer.GetLocalizedDate(date);

        /*let prettyDate = "";

        let today = new Date();
        let yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

        let dateObj = new Date(date);

        if(dateObj.toDateString() === today.toDateString()) {
            prettyDate = "Today";
        } else if(dateObj.toDateString() === yesterday.toDateString()) {
            prettyDate = "Yesterday";
        } else {
            prettyDate = dateObj.toLocaleDateString();
        }

        return prettyDate;*/
    }

    const GetEventTypeName = (type) => {
        switch(type) {
            case 'Journey':
                return translationContainer.GetTranslation('tables', 'hmj', 'History Makers Journey');
            case 'Module 1':
                return translationContainer.GetTranslation('tables', 'hm1', 'History Makers Module 1');
            case 'Module 2':
                return translationContainer.GetTranslation('tables', 'hm2', 'History Makers Module 2');
            case 'Mobilization':
                return translationContainer.GetTranslation('tables', 'mobilization', 'Flight School');
            case 'Summit':
                return translationContainer.GetTranslation('tables', 'alumni-summit', 'Alumni Summit');
            default:
                return "Unknown";
        }
    }

    const GetEventTypeMedium = (type) => {
        switch(type) {
            case "Online":
                return translationContainer.GetTranslation("tables", "online", "Online Event");
            case "In-Person":
                return translationContainer.GetTranslation("tables", "in-person", "In-Person Event");
            case "Hybrid":
                return translationContainer.GetTranslation("tables", "hybrid", "Hybrid Event");
            default:
                break;
        }
    }

    const GetEventTypeImage = (type) => {
        switch(type) {
            case 'Journey':
                return `${process.env.PUBLIC_URL}/training/en/HMJ.jpg`;
            case 'Module 1':
                return `${process.env.PUBLIC_URL}/training/en/M1.jpg`;
            case 'Module 2':
                return `${process.env.PUBLIC_URL}/training/en/M2.jpg`;
            case 'Mobilization':
                return `${process.env.PUBLIC_URL}/training/en/M1.jpg`;
            case 'Summit':
                return "https://aldersgate-college.s3.amazonaws.com/website/images/events/summit.png";
            default:
                return "Unknown";
        }
    }


    return (
        <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
            <Card>
            <Grid container>
                <Grid item xs={12} md={6} p={xsMatches ? 2 : 0}>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start', gap:'6px'}}>
                        <Box component="img" sx={{width:'100px', height:'100%'}} src={GetEventTypeImage(eventData?.eventType)} />
                        <ThemeProvider theme={rTheme}>

                        <Box sx={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignContent:'flex-start', alignItems:'flex-start'}}>
                            <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
                                <Box>
                                    <Typography variant="h6" color="text.primary"><TranslationText page='tables' dbKey='training-information'>Training Information</TranslationText></Typography>
                                </Box>
                            </Box>
                            <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
                                <Box>
                                    <Typography variant="p" color="text.primary" sx={{fontSize: '1.0rem'}}>{eventData?.eventName}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
                                <Box>
                                    <Typography variant="p" color="text.primary" >{translationContainer.GetLocalizedDateRange(eventData?.startingDate, eventData?.endingDate, dateOptions)}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
                                <Box>
                                    <Typography variant="p" color="text.primary" >{GetEventTypeName(eventData?.eventType)}</Typography>
                                </Box>
                            </Box>

                            <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
                                <Box>
                                    <Typography variant="p" color="text.primary" >{GetEventTypeMedium(eventData?.eventMedium)}</Typography>
                                </Box>
                            </Box>

                            {!isAdmin ? (
                            <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
                                <Box>
                                    <Typography variant="p" color="text.primary" ><TranslationText page='tables' dbKey='status'>Status</TranslationText>: {!eventData?.dropped ? translationContainer.GetTranslation('tables', 'registered', 'Registered') : translationContainer.GetTranslation('tables', 'dropped', 'Dropped')}</Typography>
                                </Box>
                            </Box>
                            ) : null}

                        </Box>
                        </ThemeProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} p={2}>
                    {!isAdmin ? (
                        <Box>
                    {smMatches ? (
                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-end', alignContent:'flex-end', alignItems:'flex-end'}}>
                        <Box sx={{width:'200px'}}>
                            <Button variant="contained" color="primary" sx={{width:'100%', height:'100%'}} onClick={()=>{setTicketModalOpen(true)}} startIcon={<QrCode2Icon />}><TranslationText page='tables' dbKey='view-ticket'>View Ticket</TranslationText></Button>
                        </Box>
                    </Box>
                    ) : (
                    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-end', alignContent:'flex-end', alignItems:'flex-end'}}>
                        <Box sx={{width:'100%', height:'100%'}}>
                            <Button variant="contained" color="primary" sx={{width:'100%', height:'100%'}} onClick={()=>{setTicketModalOpen(true)}} startIcon={<QrCode2Icon />}><TranslationText page='tables' dbKey='view-ticket'>View Ticket</TranslationText></Button>
                        </Box>
                    </Box>
                    )}
                    </Box>) : null}
                </Grid>
            </Grid>
            </Card>

            {eventData?.owner === true || isAdmin ? (
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignContent:'flex-end', width:'100%', gap:'6px'}}>
                <Box>
                    <Button variant="contained" color="primary" onClick={()=>{setNewPostOpen(true)}} startIcon={<NoteAddIcon />}><TranslationText page='tables' dbKey='add-post'>Add Post</TranslationText></Button>
                </Box>
            </Box>
            ) : (
                <div></div>
            )}
            <Box>
                <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 900: 1}}>
                    <Masonry gutter="10px">
                        {BuildFeed(feedItems)}
                    </Masonry>
                </ResponsiveMasonry>
            </Box>
            <Box>
                <Modal open={newPostOpen} onClose={()=>{setNewPostOpen(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={modalStyle}>
                        <AddEventFeedPost signupID={signupID} onCancel={()=>{setNewPostOpen(false)}}/>
                    </Box>
                </Modal>
            </Box>
            <Box>
                <Modal
                    open={ticketModalOpen}
                    onClose={()=>{setTicketModalOpen(false)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={ticketStyle}>
                        <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', height:'100%', width:'100%'}}>
                            <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', height:'100%', width:'100%', outline: "4px solid #3573b9", pb:'6px'}}>
                                <Box>
                                    {xsMatches ? (
                                        <h1>{eventData?.eventName}</h1>
                                    ) : (
                                        <h3>{eventData?.eventName}</h3>
                                    )}
                                </Box>
                                <Box>
                                    {translationContainer.GetLocalizedDateRange(eventData?.startingDate, eventData?.endingDate, dateOptions)}
                                </Box>
                                
                                <Box sx={{height:'80%', width:'80%'}}>
                                <QRCodeSVG id="123456" value={"{\"eID\":\"" + eventData?.signupID + "\",\"pID\":\"" + eventData?.participant?.adID + "\"}"} 
                                level={"M"}
                                width={"100%"}
                                height={"100%"}
                                includeMargin={true}
                                />

                                </Box>

                                <Box>
                                    <TranslationText page='tables' dbKey='registered'>Registered</TranslationText> {GetPrettyDate(eventData?.participant?.signupDate)}
                                </Box>
                            </Box>
                            <Box mt={4} sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center'}}>
                                {smMatches ? (
                                    <Box>
                                        <Button variant="contained" size="large" color="error" onClick={()=>{setTicketModalOpen(false)}}>
                                            <TranslationText page='tables' dbKey='close'>Close</TranslationText>
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button variant="contained" size="large" color="error" fullWidth onClick={()=>{setTicketModalOpen(false)}}>
                                        <TranslationText page='tables' dbKey='close'>Close</TranslationText>
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </Box>
        
    );
}
import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import TranslationText from '../TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack';


export default function ViewParticipant(props) {
    const {closeForm, updateInformation } = props;
    const {updateData, setSnackbarMessage} = updateInformation;
    const [formData, setFormData] = useState(props.data);
    const [changedData, setChangedData] = useState({});
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);
    const [showContact, setShowContact] = useState(false);

    const translationContainer = new TranslationContainer();

    const handleCloseForm = () => {
        closeForm(true);
    }
    
    const handleTextChange = (event) => {
      let value = {[event.target.id]: event.target.value};
    
      setChangedData(prevState => {
          let newState = {...prevState};
          newState[event.target.id] = event.target.value;
          return {...newState};
      });
    
      setFormData(prevState => {
        let newState = {...prevState};
        newState[event.target.id] = event.target.value;
        return {...newState};
      });
    }
    
    const handleSubmit = () => {
      let editedData = {...changedData};
      editedData.id = undefined;
      let eventID = formData.eventID;
      let adID = formData.adID;

      aldersgateContext.PostToAPI('event/' + eventID + '/updateparticipant/' + adID, editedData, null, 1).then((success, err) => {
        if(success) {
            updateData(prevState => {
                let newState = prevState;
                newState = newState+1;
                return {...newState};
            });
              
            setSnackbarMessage("Participant Updated: " + (formData.fName ? formData.fName : "null") + " " +  (formData.lName ? formData.lName : "null") );
    
        }

        if(err) {
            console.log("Error: " + err);
        }

    }).catch((error) => {
        console.log("Error: " + error);
    });
      
      //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/updateparticipant/' + adID + '?api-key=foo', {
    
    }

    const GetAllParticipantData = () => {
        let eventID = formData.eventID;
        let adID = formData.adID;

        aldersgateContext.GetFromAPI('event/' + eventID + '/participant/' + adID, null, 1).then((success, err) => {

            if(success) {
                setFormData(prevState => {
                    let newState = {...prevState};
                    newState.email = success.email;
                    newState.phoneNumber = success.phoneNumber;
                    return {...newState};
                  });

                  setShowContact(true);
            }

            if(err) {
                console.log("Error: " + err);
            }

        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const handleDeleteParticipant = () => {
        let eventID = formData.eventID;
        let adID = formData.adID;

        
        aldersgateContext.PostToAPI('event/' + eventID + '/deleteparticipant/' + adID, {myid:adID}, null, 1).then((success, err) => {
            if(success) {
                updateData(prevState => {
                    let newState = prevState;
                    newState = newState+1;
                    return {...newState};
                  });
                  
                  setSnackbarMessage("Participant Deleted: " + (formData.fName ? formData.fName : "null") + " " +  (formData.lName ? formData.lName : "null") );
      
                  handleCloseForm();
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/deleteparticipant/' + adID + '?api-key=foo', {
            

    }

    const OpenWhatsApp = () => {

        if(!showContact) {
            let eventID = formData.eventID;
            let adID = formData.adID;

            aldersgateContext.GetFromAPI('event/' + eventID + '/participant/' + adID, null, 1).then((success, err) => {

                if(success) {
                    if(success.phoneNumber === undefined || success.phoneNumber === null || success.phoneNumber === "")
                        return;
                    
                    let url = "https://wa.me/" + success.phoneNumber;

                    window.open(url, '_blank');
                }

                if(err) {
                    console.log("Error: " + err);
                }

            }).catch((error) => {
                console.log("Error: " + error);
            });

        } else {
            if(formData.phoneNumber === undefined || formData.phoneNumber === null || formData.phoneNumber === "")
                return;
                
            let url = "https://wa.me/" + formData.phoneNumber;

            window.open(url, '_blank');
        }

        //https://api.whatsapp.com/send?phone=${mobile}&text=Hello%20World
    }

    const RenderDelete = () => {
        if(deleteConfirm) {
            return (
                <Box sx={{position:'absolute', top:10, right:10 }}>
                    <Box sx={{display:'inline-block', mr:1}}>
                        <Typography variant="body1" color="textSecondary"><TranslationText page='tables' dbKey='delete'>Delete?</TranslationText></Typography>
                    </Box>
                    <Box sx={{display:'inline-block'}}>
                        <Button variant="contained" color="error" onClick={handleDeleteParticipant}><TranslationText page='tables' dbKey='yes'>Yes</TranslationText></Button>
                    </Box>
                    <Box sx={{display:'inline-block', ml:1}}>
                        <Button variant="contained" onClick={() => setDeleteConfirm(false)}><TranslationText page='tables' dbKey='no'>No</TranslationText></Button>
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box sx={{position:'absolute', top:10, right:10 }}>
                    <IconButton aria-label="delete" onClick={() => setDeleteConfirm(true)}>
                        <Delete />
                    </IconButton>
                </Box> 
            );
        }
    }

    return (
        <Box mt={2} sx={{position:'relative'}}>
            <RenderDelete />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item sx={{textAlign:"center"}}>
                            <Typography component="h2" variant="h4" color="textSecondary">{formData.fName} {formData.lName}</Typography>
                            <TranslationText page='tables' dbKey='edit-participant'>View and edit participant details</TranslationText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}  sx={{mt:2}}>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={{mr:1}}>
                        <AssignmentIcon color="action"/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" align="left" color="textSecondary"><TranslationText page='tables' dbKey='contact-details'>Contact Details</TranslationText></Typography>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        label={translationContainer.GetTranslation('tables', 'first-name', 'First Name')}
                        id="fName"
                        value={formData.fName}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                        variant="standard"
                        label={translationContainer.GetTranslation('tables', 'last-name', 'Last Name')}
                        id="lName"
                        value={formData.lName}
                        sx={{  width: '100%' }}
                        onChange={handleTextChange}
                    />
                </Grid>
                
                <Grid item xs={0} sm={12} >
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={2}>
                    <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation('tables', 'email', 'Email')}
                            id="email"
                            value={formData.email}
                            disabled={!showContact}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {!showContact ? 
                                <IconButton aria-label="Show Email Address" onClick={() => GetAllParticipantData()}>
                                    <VisibilityOffIcon />
                                </IconButton>
                            : 
                                null
                            }
                        </Box>
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={2}>
                    <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation('tables', 'phone-number', 'Phone Number')}
                            id="phoneNumber"
                            value={formData.phoneNumber}
                            disabled={!showContact}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {!showContact ? 
                                <IconButton aria-label="Show Phone Number" onClick={() => GetAllParticipantData()}>
                                    <VisibilityOffIcon />
                                </IconButton>
                            : 
                                null
                            }
                            <IconButton aria-label="WhatsApp" onClick={OpenWhatsApp}>
                                <WhatsAppIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </Grid>


                <Grid item xs={12}  sx={{mt:2}}>

                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AssessmentIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary"><TranslationText page='tables' dbKey='event-details'>Event Details</TranslationText></Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="dropped">
                        <TranslationText page='tables' dbKey='attending'>Attending</TranslationText>
                        </InputLabel>
                        <NativeSelect
                            value={formData.dropped}
                            onChange={handleTextChange}
                            sx={{  width: '100%' }}
                            inputProps={{
                            name: 'dropped',
                            id: 'dropped',
                            }}
                        >
                            <option value={false}>{translationContainer.GetTranslation('tables', 'yes', 'Yes')}</option>
                            <option value={true}>{translationContainer.GetTranslation('tables', 'dropped', 'Dropped')}</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>


                <Grid item xs={12} mt={4}>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button variant="contained" onClick={handleCloseForm} color="error"><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                        </Grid>

                        <Grid item>
                            <Button variant="contained" color="success" onClick={handleSubmit}><TranslationText page='tables' dbKey='save'>Save</TranslationText></Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Box>

      );
}


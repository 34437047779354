import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import RDTeamsView from './RDETeams/RDTeamsView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

export default function RDETeams(props) {

    const {filterData} = props;
    const { groupIDs } = props;

    const [teamsData, setTeamsData] = useState([]);
    const [receivedData, setReceivedData] = useState(false);
    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetTeams();
    }, [groupIDs, filterData]);

    const GetTeams = () => {

        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(filterData?.startDate !== undefined && filterData?.startDate !== null)
            query += '&startingDate=' + filterData?.startDate.toISOString();

        if(filterData?.endDate !== undefined && filterData?.endDate !== null)
            query += '&endingDate=' + filterData?.endDate.toISOString();
        
        aldersgateContext.GetFromAPI('stats/r/count/teams', query, 1).then((success, err) => {
            if(success) {
                ProcessData(success);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const ProcessData = (data) => {
        if(data === undefined || data === null)
            return;

        let teamsList = [];
        let idCount = 0;
        data.groups.forEach(element => {
            element.id = idCount++;
            teamsList.push(element);
        });

        //order by name - ascending
        teamsList.sort((a, b) => (a.name > b.name) ? 1 : -1);

        setTeamsData(teamsList);
        setReceivedData(true);
    }


    return(
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Paper>
                <Box p={1}>
                    <Box sx={{height:'50px'}}>
                        <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                            Teams
                        </Typography>
                    </Box>
                    <Box sx={{height:'300px'}}>
                        {receivedData ? <RDTeamsView listData={teamsData} filterData={filterData} /> : <Box sx={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../context/aldersgate';
import RegionOverview from './RegionOverview';
import RegionAnalytics from './RegionAnalytics';
import RDashAnalytics from './RDashboard/RDashAnalytics';
import RegionReporting from './RegionReporting';
import RegionTeams from './RegionTeams';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GridViewIcon from '@mui/icons-material/GridView';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { set } from 'date-fns';

export default function RegionView(props) {

    const [value, setValue] = useState(0);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedItem, setSelectedItem] = useState('All');
    const [regionList, setRegionList] = useState([]);
    const [teamList, setTeamList] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        GetMemberGroupsList();
    }, []);

    useEffect(() => {
        if(regionList.length > 0)
            GetTeamList();

    }, [selectedRegion]);

    useEffect(() => {
        if(teamList.length > 0) {
            setSelectedGroups([teamList[0].adID]);
            setSelectedItem(teamList[0].adID);
        }
    }, [teamList]);


    const GetRegionList = () => {
        aldersgateContext.GetFromAPI('group/getlist/regions', '', 1).then((success, err) => {
            if(success) {
                console.log(success);
                setRegionList(success);

                if(success.length > 0){
                    setSelectedRegion(success[0].adID)

                    let groupList = [];
                    //setSelectedGroups(success[0].adID)
                }
            }

            if(err) {
                console.log("Error: " + err);
            }
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const GetTeamList = () => {     
        aldersgateContext.GetFromAPI('group/getlist/childgroups/' + selectedRegion, '', 1).then((success, err) => {
            if(success) {
                setTeamList(success);
                // setSelectedGroups([success[0].adID]);
                // setSelectedItem(success[0].adID);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/group/getlist/childgroups/'+ selectedRegion + '?api-key=foo', {
    }

    const GetMemberGroupsList = () => {
        aldersgateContext.GetFromAPI('group/getlist/viewgroups', '', 1).then((success, err) => {
            if(success) {
                setTeamList(success);
            }
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const RenderTabContent = () => {
        switch(value) {
            case 0:
                return <RegionOverview groupIDs={selectedGroups}/>;
            // case 1:
            //     return <RegionTeams groupID={selectedGroups} />;
            // case 2:
            //     return <RegionReporting groupID={selectedGroups} />;
            case 1:
                return <RDashAnalytics groupIDs={selectedGroups}/>;
            default:
                return <div>Overview</div>;
        }
    }

    const SetSelectedRegion = (e) => {
        setSelectedRegion(e.target.value);
        setSelectedGroups(e.target.value);
    }

    const SetSelectedGroup = (e) => {
        // if(e.target.value == "Region") {
        //     setSelectedGroups(selectedRegion);
        // } else {
            setSelectedItem(e.target.value);

            let groupList = [];
            if(e.target.value === "All") {
                teamList.forEach((team) => {
                    groupList.push(team.adID);
                });
            } else {
                groupList.push(e.target.value);
            }

            console.log('SetSelectedGroup: ', groupList);

            setSelectedGroups(groupList);
        //}
    }

    /*
                    <FormControl>
                    <InputLabel variant="standard" htmlFor="nativeSelect-region">
                    Region
                    </InputLabel>
                    <NativeSelect
                    value={selectedRegion}
                    inputProps={{
                        name: 'Region',
                        id: 'nativeSelect-region',
                    }}
                    onChange={SetSelectedRegion}
                    >
                        {regionList.map((region) => {
                            return <option key={region.adID} value={region.adID}>{region.name}</option>
                        })}
                    </NativeSelect>
                </FormControl>
                */
  
    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box mb={2} sx={{display:'flex', alignContent:'flex-start', gap:2}}>

                {teamList !== undefined && teamList.length > 0 ? (
                <FormControl>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Team
                    </InputLabel>
                    <NativeSelect
                    value={selectedItem}
                    inputProps={{
                        name: 'Team',
                        id: 'uncontrolled-native',
                    }}
                    onChange={SetSelectedGroup}
                    >
                        <option value="All">All</option>
                        {teamList.map((team) => {
                            return <option key={team.adID} value={team.adID}>{team.name}</option>
                        })}
                    </NativeSelect>
                </FormControl>
                ) : null}
            </Box>
            <Box>
                <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="icon label tabs example"
                >
                    <Tab icon={<GridViewIcon aria-label="Overview" />} label="Overview" />
                    {/* <Tab icon={<PeopleAltIcon aria-label="Teams" />} label="Teams" />
                    <Tab icon={<AssessmentIcon aria-label="Reporting" />} label="Reporting" /> */}
                    <Tab icon={<AnalyticsIcon aria-label="Analytics" />} label="Analytics" />
                </Tabs>
            </Box>
            <Box mt={2}>
                <RenderTabContent />
            </Box>
        </Box>
    );
}

/*
<option value='4iCC0AaFd3'>Africa English</option>
<option value='RRXJxYiv85'>Horn of Africa</option>
<option value='GxfhQmjvO-'>Southern Africa</option>

*/
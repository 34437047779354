import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function RDTopNumbersLong(props) {
    const {filterData} = props;
    const { groupIDs } = props;

    console.log("GroupIDs: " + JSON.stringify(groupIDs));

    const [displayData, setDisplayData] = useState({eventData:{title: "Training Events", count: 0}, leaderData:{title: "Leaders Trained", count: 0}});

    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetEventsCount();
        GetTrainedCount();

        //unmount
        return () => {
            setDisplayData({eventData:{title: "Training Events", count: 0}, leaderData:{title: "Leaders Trained", count: 0}});
        }
    }, [groupIDs, filterData]);


    const GetEventsCount = () => {
        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(filterData?.startDate !== undefined && filterData?.startDate !== null)
            query += '&startingDate=' + filterData?.startDate?.toISOString();

        if(filterData?.endDate !== undefined && filterData?.endDate !== null)
            query += '&endingDate=' + filterData?.endDate?.toISOString();
        
        aldersgateContext.GetFromAPI('stats/r/count/events', query, 1).then((success, err) => {
            if(success) {

                setDisplayData( prev => {
                    let newDisplay = {...prev};
                    newDisplay.eventData.count = success.count;
                    return ({...prev, newDisplay});
                    }
                )
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const GetTrainedCount = () => {
        if(groupIDs === undefined || groupIDs === null)
            return;

        let query = '&groupID=' + groupIDs;

        if(filterData.startDate !== undefined && filterData.startDate !== null)
            query += '&startingDate=' + filterData.startDate.toISOString();

        if(filterData.endDate !== undefined && filterData.endDate !== null)
            query += '&endingDate=' + filterData.endDate.toISOString();
        
        aldersgateContext.GetFromAPI('stats/r/count/leaders', query, 1).then((success, err) => {
            if(success) {

                setDisplayData( prev => {
                    let newDisplay = {...prev};
                    newDisplay.leaderData.count = success.count;
                    return ({...prev, newDisplay});
                    }
                )
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    //first day this year
    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    //first day 5 years ago
    const firstDay5YearsAgo = new Date(new Date().getFullYear(), 0, 1);

    //last day this year
    const lastDay = new Date(new Date().getFullYear(), 11, 31);

    return(
        <Paper sx={{display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
            <Box p={1}>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignContent:'center', alignItems:'center', width:'100%', height:'100px'}}>
                    
                    <Box >
                        <Typography variant="h5" component="h6" color="info" sx={{color:"grey"}} gutterBottom>
                            {displayData.eventData.title}
                        </Typography>
                        <Typography variant="h3" component="h4" color="info" sx={{color:"grey"}} gutterBottom>
                            {displayData.eventData.count}
                        </Typography>
                    </Box>

                    <Box >
                        <Typography variant="h5" component="h6" color="info" sx={{color:"grey"}} gutterBottom>
                            {displayData.leaderData.title}
                        </Typography>
                        <Typography variant="h3" component="h4" color="info" sx={{color:"grey"}} gutterBottom>
                            {displayData.leaderData.count}
                        </Typography>
                    </Box>
                
                </Box>
            </Box>
        </Paper>
    );
}